import { PlaceholderImage } from "@/components/PlaceholderImage.tsx";
import { ItemDetailsPanel } from "@/components/order-guide/ItemDetailsPanel.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Drawer, DrawerClose, DrawerContent, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger } from "@/components/ui/drawer.tsx";
import { useScreenBreakpoint } from "@/hooks";
import { ProductMetadataDto } from "@/store/IRN.API.ts";
import { Float } from "@headlessui-float/react";
import { Popover } from "@headlessui/react";

export function ProductTableItemDetailsCell({ item, showSku }: { item: ProductMetadataDto; showSku?: boolean }) {
  const currentBreakpoint = useScreenBreakpoint();

  let imageUrl = item.thumbnailUrl;

  if (item.thumbnailUrl) {
    imageUrl = item.thumbnailUrl;
  } else if (item.imageUrls && item.imageUrls.length > 0) {
    imageUrl = item.imageUrls[0];
  } else {
    imageUrl = null;
  }

  const segments = [item.brand];

  if (showSku && item.products) {
    for (const product of item.products) {
      if (product.sku) {
        segments.push(`SKU: ${product.sku}`);
        break;
      }
    }
  }

  segments.push(item.unitSize ?? "");

  const descriptionText = segments.filter((x) => !!x).join(" - ");

  return (
    <div className="flex gap-4" key={"ptidc" + item.id}>
      {imageUrl && (
        <object data={imageUrl!} type="image/png" className="h-11 w-11 rounded object-cover" key={imageUrl}>
          <PlaceholderImage className="h-11 w-11 rounded text-brand-300" />
        </object>
      )}
      {!imageUrl && (
        <div className="h-11 w-11 rounded object-cover" key={imageUrl}>
          <PlaceholderImage className="h-11 w-11 rounded text-brand-300" />
        </div>
      )}
      {currentBreakpoint === "phone" && (
        <Drawer>
          <DrawerTrigger asChild>
            <div className="text-left cursor-pointer">
              <p className="text-sm font-medium text-gray-900">{item.name}</p>
              <p className="text-xs text-gray-400">{item.brand}</p>
              <p className="text-xs text-gray-400">{item.unitSize}</p>
            </div>
          </DrawerTrigger>

          <DrawerContent>
            <div className="mx-auto w-full max-w-sm overflow-y-auto">
              <DrawerHeader>
                <DrawerTitle>{item.name}</DrawerTitle>
              </DrawerHeader>

              <ItemDetailsPanel productLink={item} key={"details_panel" + item.id} />
              <DrawerFooter>
                <DrawerClose asChild>
                  <Button>Close</Button>
                </DrawerClose>
              </DrawerFooter>
            </div>
          </DrawerContent>
        </Drawer>
      )}
      {currentBreakpoint !== "phone" && (
        <Popover className={item.id.toString()} key={"details_popover" + item.id}>
          <Float
            portal
            autoPlacement
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
            tailwindcssOriginClass
          >
            <Popover.Button key={"details_panel_btn" + item.id}>
              <div className="text-left">
                <p className="text-sm font-medium text-gray-900">{item.name}</p>
                <p className="hidden sm:block text-sm text-gray-400">{descriptionText}</p>
                {currentBreakpoint === "phone" && (
                  <>
                    <p className="text-xs text-gray-400">{item.brand}</p>
                    <p className="text-xs text-gray-400">{item.unitSize}</p>
                  </>
                )}
              </div>
            </Popover.Button>
            <Popover.Panel className="z-10 mt-3 w-screen max-w-sm rounded-lg border border-brand-600 bg-white px-4 sm:px-0 lg:max-w-3xl">
              <ItemDetailsPanel productLink={item} key={"details_panel" + item.id} />
            </Popover.Panel>
          </Float>
        </Popover>
      )}
    </div>
  );
}
