import { IrnInput, IrnSelect } from "@/components/forms/IrnInput.tsx";
import { RefetchOrderGuideSummariesType } from "@/components/order-guide-selection-widget/RefetchOrderGuideSummariesType.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form } from "@/components/ui/form.tsx";
import { selectActiveBusiness, selectBusinesses } from "@/store/Business.slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { useCreateOrderGuideMutation } from "@/store/IRN.API.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  businessId: Yup.string().required(),
  name: Yup.string().required(),
});

export function CreateOrderGuideDialog({ children, refetchSummaries }: { children: ReactNode; refetchSummaries: RefetchOrderGuideSummariesType }) {
  const [open, setOpen] = useState(false);
  // const userInfo: UserInfoDto | undefined = useAppSelector(selectUserInfo);
  const [updateTrigger, updateResult] = useCreateOrderGuideMutation();
  const businesses = useAppSelector(selectBusinesses);
  const activeBusiness = useAppSelector(selectActiveBusiness);

  const dispatch = useAppDispatch();
  const form = useForm({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (updateResult.isSuccess) {
      setOpen(false);
      dispatch(showNotification({ message: "Order guide created", severity: "success" }));
    }
  }, [dispatch, updateResult.isSuccess]);

  useEffect(() => {
    if (activeBusiness?.id) {
      form.setValue("businessId", activeBusiness.id.toString());
    }
  }, [activeBusiness, form]);

  if (!activeBusiness?.id) {
    return null;
  }

  const onSubmit = (data: any) => {
    // Log.debug(data);
    updateTrigger({
      businessId: parseInt(data.businessId),
      name: data.name,
    }).then((result) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (result.data) {
        setOpen(false);
        refetchSummaries({
          requestPolicy: "network-only",
        });
      }
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
        {/*<Button variant="link">Update</Button>*/}
      </DialogTrigger>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>Create a new order guide </DialogTitle>
          <DialogDescription>Order guides are a way to organize your orders. You can create as many as you like.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <IrnSelect
              label="Business"
              control={form.control}
              name="businessId"
              options={businesses.map((business) => ({
                label: business.name,
                value: business.id!.toString(),
              }))}
            />

            <IrnInput control={form.control} name="name" label="Guide name" />

            <DialogFooter>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
