import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Switch } from "@/components/ui/switch.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks";
import { OrderGuideUploadDto, useDeleteOrderGuideUploadMutation } from "@/store/IRN.API";
import { showNotification } from "@/store/Toast.slice.ts";
import { confirmAllExactMatches, reset } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import {
  resetOrderGuideUploadState,
  resetWizard,
  selectShowAdvancedControls,
  setShowAdvancedControls,
} from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice.ts";
import { ArrowRight, MoreVertical, Trash } from "lucide-react";
import { useNavigate } from "react-router-dom";

export function ActionButton({ upload }: { upload?: OrderGuideUploadDto }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const showAdvancedControls = useAppSelector(selectShowAdvancedControls);
  const [deleteOrderGuideUploadTrigger] = useDeleteOrderGuideUploadMutation();
  const confirmationDialog = useConfirm();

  if (!upload) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="px-2">
          <MoreVertical className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="text-emerald-600"
          onClick={async () => {
            const confirmed = await confirmationDialog({
              title: "Confirm all matches",
              body: (
                <div className="flex flex-col gap-5">
                  <p>Are you sure you want to automatically confirm all 100% confidence matches?</p>
                  <p>This may take a while to complete. Depending on the size of your upload, you may want to grab a cup of coffee.</p>
                </div>
              ),
              actionButton: "Confirm All",
              actionVariant: "success",
              // cancelButton: "Cancel",
              // cancelVariant: "link",
            });

            if (confirmed) {
              dispatch(confirmAllExactMatches());
            }
          }}
        >
          <ArrowRight className="mr-2 h-4 w-4" />
          <span>Confirm all exact matches</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem>
          <div className="flex items-center gap-1">
            <Switch id="showAdvancedFeatures" checked={showAdvancedControls} onCheckedChange={(checked) => dispatch(setShowAdvancedControls(checked))}></Switch>
            <Label htmlFor="showAdvancedFeatures">Show Advanced Features</Label>
          </div>
        </DropdownMenuItem>
        <DropdownMenuItem
          className="cursor-pointer text-red-500 hover:bg-red-200 focus:bg-red-200 focus:text-red-600"
          onClick={async () => {
            const confirmed = await confirmationDialog({
              title: "Remove Upload",
              body: (
                <div className="flex flex-col gap-5">
                  <p>Are you sure you want to delete this upload?</p>
                  <p className="font-semibold">{upload?.fileKey}</p>
                  <p className="font-semibold text-destructive">This action cannot be undone.</p>
                </div>
              ),
              actionButton: "Delete",
              actionVariant: "destructive",
              cancelButton: "Cancel",
              cancelVariant: "link",
            });
            dispatch(resetOrderGuideUploadState());
            dispatch(resetWizard());
            dispatch(reset());
            navigate(`/app/dashboard`, {
              replace: true,
            });

            if (confirmed) {
              deleteOrderGuideUploadTrigger(upload!.id!)
                .unwrap()
                .then(() => {
                  dispatch(showNotification({ message: "Upload deleted", severity: "success" }));
                })
                .catch(() => {
                  dispatch(showNotification({ message: "Upload failed to delete", severity: "error" }));
                });
            }
          }}
        >
          <Trash className="mr-2 h-4 w-4" />
          <span>Delete</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
