import { PlusIcon } from "@heroicons/react/24/solid";

import { CreateOrderGuideDialog } from "@/components/dialogs/create-order-guide/CreateOrderGuideDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { RefetchOrderGuideSummariesType } from "./RefetchOrderGuideSummariesType.tsx";

export function NoOrderGuides({ refetchSummaries }: { refetchSummaries: RefetchOrderGuideSummariesType }) {
  return (
    <>
      <div className="flex flex-col items-center justify-center gap-5 rounded-lg border-2 border-dashed border-gray-500 p-10">
        <p className="">No order guides yet</p>
        <CreateOrderGuideDialog refetchSummaries={refetchSummaries}>
          <Button>
            <PlusIcon className="-ml-1 mr-2 h-5 w-5" />
            Create one
          </Button>
        </CreateOrderGuideDialog>
        <div className="space-y-2 text-center text-xs">
          <p>Order guides are a great way to save time and money.</p>
          <p>They are items you frequently order that you can manage regularly.</p>
          <p>They help you keep track of your regular item orders to help make sure you remember everything.</p>
          <p>
            Choose from our list of products across all our supported distributors, and we&apos;ll find you the best prices when you&apos;re ready to order.
          </p>
        </div>
      </div>

      {/*<CreateOrderGuide businessNames={businessNames} refetchSummaries={refetchSummaries} shown={shown} setShow={setShow} />*/}
    </>
  );
}
