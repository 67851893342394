import { AddToOrderGuideDialog } from "@/components/dialogs/add-to-order-guide/AddToOrderGuideDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select.tsx";
import { ProductMetadataDto, SearchFacetDto } from "@/store/IRN.API.ts";
import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon, ChevronLeftIcon, ChevronRightIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import { PlusCircleIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { useDebounce } from "usehooks-ts";
import { formClasses } from "../../forms/fields/FormUtils.tsx";
import { ProductsTableListing } from "./ProductsTableListing.tsx";

interface Props {
  businessId: number;

  searchFacets: SearchFacetDto[];
  defaultFilter?: string;

  clearSearchFacetsOnFilterChange?: boolean;

  actionsColumnHeader?: string;
  actionsColumn?: (product: ProductMetadataDto) => React.ReactNode;

  title?: string;
  showPaginationByDefault?: boolean;

  filtersSlot?: React.ReactNode;
}

export function ProductsTable({
  searchFacets,
  clearSearchFacetsOnFilterChange,
  defaultFilter,
  actionsColumn,
  actionsColumnHeader,
  businessId,
  title,
  showPaginationByDefault,
  filtersSlot,
}: Props) {
  const [selectedPage, setSelectedPage] = useState(1);
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState(defaultFilter ?? "");
  const debouncedFilter = useDebounce(filter, 500);

  useEffect(() => {
    if (defaultFilter !== filter) {
      setFilter(defaultFilter ?? "");
    }
  }, [defaultFilter]);

  useEffect(() => {
    setTotalPages(Math.ceil(totalProducts / selectedPageSize));
  }, [totalProducts, selectedPageSize]);

  const goToPage = (page: number) => {
    if (page < 1 || page > totalPages) return;
    setSelectedPage(page);
  };

  const renderPageButton = (page: number) => (
    <button
      key={"pg" + page}
      aria-current={selectedPage === page ? "page" : undefined}
      onClick={() => goToPage(page)}
      className={`relative z-10 inline-flex items-center ${
        selectedPage === page ? "bg-brand-600 text-white" : "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      } px-4 py-2 text-sm font-semibold focus:z-20 focus:outline-offset-0`}
    >
      <span className="max-w-[3ch]">{page}</span>
    </button>
  );

  const renderEllipsis = (key: string) => (
    <span
      className="relative inline-flex cursor-pointer select-none items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
      key={key}
    >
      ...
    </span>
  );

  const renderPaginationButtons = () => {
    const buttons = [];

    buttons.push(
      <button
        key="first"
        onClick={() => goToPage(1)}
        className="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        disabled={selectedPage === 1}
      >
        <span className="sr-only">Previous</span>
        <ChevronDoubleLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>,
    );

    buttons.push(
      <button
        key="prev"
        onClick={() => goToPage(selectedPage - 1)}
        className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        disabled={selectedPage === 1}
      >
        <span className="sr-only">Previous</span>
        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
      </button>,
    );

    if (selectedPage > 3) buttons.push(renderEllipsis("pre-elip"));

    for (let i = Math.max(1, selectedPage - 3); i <= Math.min(totalPages, selectedPage + 3); i++) {
      buttons.push(renderPageButton(i));
    }

    if (selectedPage < totalPages - 3) buttons.push(renderEllipsis("post-elip"));

    buttons.push(
      <button
        key="next"
        onClick={() => goToPage(selectedPage + 1)}
        className="relative inline-flex items-center px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        disabled={selectedPage === totalPages}
      >
        <span className="sr-only">Next</span>
        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>,
    );

    buttons.push(
      <button
        key="last"
        onClick={() => goToPage(totalPages)}
        className="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        disabled={selectedPage === totalPages}
      >
        <span className="sr-only">Last</span>
        <ChevronDoubleRightIcon className="h-5 w-5" aria-hidden="true" />
      </button>,
    );

    return buttons;
  };

  // Make sure we go back to the first page when we deal with filter changes
  useEffect(() => {
    setSelectedPage(1);
  }, [debouncedFilter]);

  return (
    <>
      <div className="px-4 sm:flex sm:items-center">
        <h2 className="text-2xl font-semibold">{title ?? "Products"}</h2>
        <div className="sm:flex-auto"></div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="text"
              name="search"
              id="search"
              className={clsx(formClasses, "pl-10 pr-3")}
              // className="block w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-3 leading-5 placeholder-gray-500 focus:border-brand-600 focus:placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-brand-600 sm:text-sm"
              placeholder="Search"
              value={filter}
              onChange={(e) => {
                setFilter(e.target.value);
              }}
            />
          </div>
        </div>
        {filtersSlot}
      </div>
      <ProductsTableListing
        businessId={businessId}
        searchFacets={searchFacets}
        page={selectedPage}
        pageSize={selectedPageSize}
        setTotalProducts={setTotalProducts}
        filter={debouncedFilter}
        clearSearchFacetsOnFilterChange={clearSearchFacetsOnFilterChange}
        actionsColumnHeader={actionsColumnHeader ?? "Add to order guide"}
        actionsColumn={
          actionsColumn ??
          ((product) => (
            <AddToOrderGuideDialog selectedItem={product} key={product.id}>
              <Button variant="no-bg" size="icon" className="text-brand-600 hover:text-brand-800">
                <PlusCircleIcon className="h-7 w-7" aria-hidden="true" />
                <span className="sr-only">Add to an order guide</span>
              </Button>
            </AddToOrderGuideDialog>
          ))
        }
      />
      {showPaginationByDefault || totalPages > 1 ? (
        <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
          <div className="flex flex-1 justify-between sm:hidden">
            <a
              href="#"
              className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Previous
            </a>
            <a
              href="#"
              className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
            >
              Next
            </a>
          </div>
          <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p className="text-sm text-gray-700">
                Showing <span className="font-medium">{(selectedPage - 1) * selectedPageSize + 1}</span> to{" "}
                <span className="font-medium">{(selectedPage - 1) * selectedPageSize + selectedPageSize}</span> of{" "}
                <span className="font-medium">{totalProducts}</span> results
              </p>
            </div>
            <div className="flex items-center gap-4">
              <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                {/*
Current: "z-10 bg-brand-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-600",
Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0"
              */}
                {renderPaginationButtons()}
              </nav>
              <div className="isolate inline-flex items-center -space-x-px rounded-md text-sm text-gray-700 shadow-sm">
                <span className="mr-3">Show</span>
                <Select defaultValue={selectedPageSize.toString()} onValueChange={(value) => setSelectedPageSize(parseInt(value))}>
                  <SelectTrigger>
                    <SelectValue placeholder={selectedPageSize.toString()} />
                  </SelectTrigger>

                  <SelectContent>
                    <SelectItem value="10">10</SelectItem>
                    <SelectItem value="25">25</SelectItem>
                    <SelectItem value="50">50</SelectItem>
                  </SelectContent>
                </Select>
                {/*<select*/}
                {/*  className="rounded border border-gray-400"*/}
                {/*  value={selectedPageSize}*/}
                {/*  onChange={(e) => {*/}
                {/*    setSelectedPageSize(parseInt(e.target.value));*/}
                {/*    goToPage(1);*/}
                {/*  }}*/}
                {/*>*/}
                {/*  <option value="10">10</option>*/}
                {/*  <option value="25">25</option>*/}
                {/*  <option value="50">50</option>*/}
                {/*</select>*/}
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
