import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { AddressAutofill } from "@mapbox/search-js-react";

export function AddressForm({ control, namePrefix }: { control: any; namePrefix?: string }) {
  if (namePrefix) {
    if (namePrefix.endsWith(".")) {
      namePrefix = namePrefix.slice(0, -1);
    }
  }

  function handleAutofillRetrieve(response: any) {
    console.log("Autofill retrieve", response);
  }

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <AddressAutofill
      accessToken="pk.eyJ1IjoiaXJud29ya3MiLCJhIjoiY20zODdpNDBlMG50OTJqb2wyczJ2OXE2cCJ9.KfSUAV4eHczu2tp4S94M0w"
      theme={{
        variables: {
          colorPrimary: "#ff0000",
          colorText: "#000",
          fontWeight: "semi-bold",
        },
      }}
      options={{
        language: "en",
        proximity: "ip",
        streets: "false",
      }}
      onRetrieve={handleAutofillRetrieve}
      onSuggest={(e) => console.log("Suggest", e)}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <div className="grid grid-cols-1 gap-2">
        <div className="col-span-full">
          <FormField
            control={control}
            name={`${namePrefix}.address1`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address</FormLabel>
                <FormControl>
                  <Input {...field} autoComplete="address-line1" />
                </FormControl>
                {/*<FormDescription>This is your public display name.</FormDescription>*/}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="col-span-full">
          <FormField
            control={control}
            name={`${namePrefix}.address2`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address 2</FormLabel>
                <FormControl>
                  <Input {...field} autoComplete="address-line2" />
                </FormControl>
                {/*<FormDescription>This is your public display name.</FormDescription>*/}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div className="grid grid-cols-3 gap-5">
          <FormField
            control={control}
            name={`${namePrefix}.city`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input {...field} autoComplete="address-level2" />
                </FormControl>
                {/*<FormDescription>This is your public display name.</FormDescription>*/}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`${namePrefix}.state`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>State</FormLabel>
                <FormControl>
                  <Input {...field} autoComplete="address-level1" />
                </FormControl>
                {/*<FormDescription>This is your public display name.</FormDescription>*/}
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name={`${namePrefix}.zipCode`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Zip Code</FormLabel>
                <FormControl>
                  <Input {...field} autoComplete="postal-code" />
                </FormControl>
                {/*<FormDescription>This is your public display name.</FormDescription>*/}
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        {/*<IrnInput label="Care Of" name={`${namePrefix}careOf`} control={control} autoComplete="address-level1" />*/}
        {/*<StateInputField control={control} label="State" name="state" autoComplete="address-level1" errors={errors.state} />*/}
      </div>

      {/*<div>*/}
      {/*  <SearchBox*/}
      {/*    accessToken="pk.eyJ1IjoiaXJud29ya3MiLCJhIjoiY20zODdpNDBlMG50OTJqb2wyczJ2OXE2cCJ9.KfSUAV4eHczu2tp4S94M0w"*/}
      {/*    options={{*/}
      {/*      language: "en",*/}
      {/*      country: "US",*/}
      {/*    }}*/}
      {/*    onRetrieve={(e) => console.log("SearchBox change", e)}*/}
      {/*  />*/}
      {/*</div>*/}
    </AddressAutofill>
  );
}
