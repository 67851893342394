// A full-page stepper component using shadcn
// Provided a list of steps, this component will render a full-page stepper with a progress bar and a list of steps. The user can click on a step to navigate to that step.
// When changing steps, the component will automatically scroll to the top of the page, and the progress bar will update to reflect the current step.
// The component also supports customizing the step labels, descriptions, and icons.

// const exampleTsx = (
//   <>
//     <FullPageStepper onComplete={handleOnComplete} onNextPage={handleOnNextPage} onPrevPage={handleOnPrevPage}>
//       <FullPageStepper.Step id="step_1" imageURL="/images/step_1.png" imageLocation="left" imageAlt="Step 1">
//         <div>Step 1</div>
//         <div>... some form...</div>
//       </FullPageStepper.Step>
//       <FullPageStepper.Step id="step_2" imageURL="/images/step_2.png" imageLocation="right" imageAlt="Step 2">
//         <div>Step 2</div>
//         <div>... some form...</div>
//       </FullPageStepper.Step>
//       {/* ... */}
//     </FullPageStepper>
//   </>
// );

import { Button } from "@/components/ui/button.tsx";
import { cn } from "@/lib/utils.ts";
import React, { ReactNode, useEffect, useRef, useState } from "react";

// The design should take the full page, and split the page in half based on the image location
// The content "side" should include a previous button, the step content, and a next button
// The image "side" should include the image
// The image should be a square, and the content side should take up the rest of the space
// The content side should be scrollable
// The image side should be fixed
// The image should be centered vertically
// The content side should be centered vertically

export interface FullPageStepperProps {
  children: ReactNode;
  onComplete?: () => void;
  onNextPage?: () => void;
  onPrevPage?: () => void;
}

export interface FullPageStepperStepProps {
  children: ReactNode;
  id: string;
  imageURL: string;
  imageLocation: "left" | "right";
  imageAlt: string;
  imageSideClassName?: string;

  canMoveNext?: () => boolean;
}

export const FullPageStepper = ({ children, onComplete, onNextPage, onPrevPage }: FullPageStepperProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const steps = React.Children.toArray(children) as React.ReactElement<FullPageStepperStepProps>[];
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [currentStep]);

  const handleNextStep = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep((prev) => prev + 1);
      onNextPage?.();
    } else {
      onComplete?.();
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
      onPrevPage?.();
    }
  };

  const canMoveForward = () => (steps[currentStep].props.canMoveNext ? steps[currentStep].props.canMoveNext!() : true);

  return (
    <div className="flex h-full">
      {steps[currentStep].props.imageLocation === "left" && (
        <div className={cn("flex-1 flex items-center justify-center", steps[currentStep].props.imageSideClassName)}>
          <img
            src={steps[currentStep].props.imageURL}
            alt={steps[currentStep].props.imageAlt}
            // className={`${steps[currentStep].props.imageLocation === "left" || steps[currentStep].props.imageLocation === "right" ? "h-full" : "w-full"}`}
          />
        </div>
      )}

      <div className="flex-1 flex items-center justify-center">
        <div className="flex flex-col items-center">
          <div className="flex-1 w-full h-full overflow-auto" ref={contentRef}>
            {steps[currentStep].props.children}
          </div>

          <div className="flex-1" />
          <div className="flex items-center justify-between w-full p-10">
            {currentStep > 0 && (
              <Button onClick={handlePrevStep} size="lg" variant="outline">
                Back
              </Button>
            )}
            {currentStep < steps.length - 1 && (
              <Button onClick={handleNextStep} size="lg" disabled={!canMoveForward()}>
                Next
              </Button>
            )}
            {currentStep === steps.length - 1 && (
              <Button onClick={handleNextStep} size="lg" variant="default" disabled={!canMoveForward()}>
                Finish
              </Button>
            )}
          </div>
        </div>
      </div>

      {steps[currentStep].props.imageLocation === "right" && (
        <div className={cn("flex-1 flex items-center justify-center", steps[currentStep].props.imageSideClassName)}>
          <img src={steps[currentStep].props.imageURL} alt={steps[currentStep].props.imageAlt} />
        </div>
      )}
    </div>
  );
};

const Step: React.FC<FullPageStepperStepProps> = ({ children }) => {
  return <>{children}</>;
};

FullPageStepper.Step = Step;
Step.displayName = "FullPageStepper.Step";
