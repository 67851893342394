import { Logo } from "@/components/Logo.tsx";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { useState } from "react";
import { Link } from "react-router-dom";

const navigation = [
  // { name: "", href: "#" },
  { name: "Solutions", href: "#" },
  // { name: "Marketplace", href: "#" },
  { name: "Company", href: "/about" },
  { name: "Pricing", href: "/pricing" },
  { name: "Log in", href: "/login", mobileHidden: true },
];

export function PublicHeader({ wrapperClassName }: { wrapperClassName?: string }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <header className={wrapperClassName}>
      <div className="mx-auto max-w-6xl">
        <div className="px-6 py-6 lg:max-w-2xl lg:pl-8 lg:pr-0">
          <nav className="flex items-center justify-between lg:justify-start" aria-label="Global">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">IRN</span>
              {/*<img alt="Your Company" className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=brand&shade=600" />*/}
              <Logo className="h-8 w-auto" />
            </Link>
            <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700 lg:hidden" onClick={() => setMobileMenuOpen(true)}>
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="hidden lg:ml-12 lg:flex lg:gap-x-14">
              {navigation.map((item) => (
                <Link key={item.name} to={item.href} className="~text-sm/xl font-semibold leading-6 text-gray-900">
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>
        </div>
      </div>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">IRN</span>
              <Logo className="h-8 w-auto" />
              {/*<img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=brand&shade=600" alt="" />*/}
            </a>
            <button type="button" className="-m-2.5 rounded-md p-2.5 text-gray-700" onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation
                  .filter((x) => !x.mobileHidden)
                  .map((item) => (
                    <Link
                      key={item.name}
                      to={item.href}
                      className="-mx-3 rounded-lg block px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                    >
                      {item.name}
                    </Link>
                  ))}
              </div>
              <div className="py-6">
                <Link to="/login" className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                  Log in
                </Link>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
