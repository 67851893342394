import { useConfirm, usePrompt } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { reorder } from "@/lib/utils.ts";
import {
  OrderGuideDto,
  OrderGuideItemCategoryDto,
  useCreateOrderGuideItemCategoryMutation,
  useDeleteOrderGuideItemCategoryMutation,
  useReorderOrderGuideCategoriesMutation,
} from "@/store/IRN.API.ts";
import { DragDropContext, Draggable, DropResult, Droppable } from "@hello-pangea/dnd";
import { Bars4Icon, PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

export function ReorderOrderGuideCategoriesDialog({
  orderGuide,
  open,
  setOpen,
}: {
  orderGuide: OrderGuideDto;
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  // const userInfo: UserInfoDto | undefined = useAppSelector(selectUserInfo);
  const [updateTrigger] = useReorderOrderGuideCategoriesMutation();
  const [deleteCategoryTrigger] = useDeleteOrderGuideItemCategoryMutation();
  const [createCategoryTrigger] = useCreateOrderGuideItemCategoryMutation();

  const confirmDialog = useConfirm();
  const inputDialog = usePrompt();

  const [categories, setCategories] = useState<OrderGuideItemCategoryDto[]>([]);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    setCategories([...orderGuide.categories].sort((a, b) => a.index - b.index).map((c) => c.category));
  }, [orderGuide.categories]);

  const onSubmit = () => {
    if (!isDirty) {
      setOpen(false);
      return;
    }

    // Log.debug(data);
    updateTrigger({
      orderGuideId: orderGuide.id!,
      reorderOrderGuideCategoriesRequest: { orderedCategoryIds: categories.map((c) => c.id!) },
    }).then((result) => {
      console.log(result);

      if (!("error" in result)) {
        setOpen(false);
      }
    });
  };

  function onDragCategoryEnd(result: DropResult) {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    console.log(result);
    const orderedCategories = reorder(categories, result.source.index, result.destination.index);

    setCategories(orderedCategories);
    setIsDirty(true);
  }

  function getSlug(category: OrderGuideItemCategoryDto) {
    return category.name!.toLowerCase().replace(/\W/g, "-");
  }

  async function deleteCategory(category: OrderGuideItemCategoryDto) {
    if (category.id === 1) {
      console.log("Cannot delete the default category");
    } else {
      const result = await confirmDialog({
        title: "Delete category",
        body: (
          <>
            <p>
              Are you sure you want to delete the <span className="font-semibold text-brand-500">{category.name}</span> category?
            </p>
            <p>All items in this category will be moved to the default category.</p>
          </>
        ),
        actionButton: "Delete",
        cancelButton: "Cancel",
      });

      if (!result) {
        return;
      }

      deleteCategoryTrigger({ orderGuideId: orderGuide.id!, categoryId: category.id! }).then((result) => {
        console.log(result);
      });
    }
  }

  async function createCategory() {
    const result = await inputDialog({
      title: "Create category",
      body: "Enter the name of the category you want to create",
      inputProps: {
        placeholder: "Category name",
      },
      actionButton: "Create",
      cancelButton: "Cancel",
    });

    if (!result) {
      return;
    }

    createCategoryTrigger({
      orderGuideId: orderGuide.id,
      createOrderGuideItemCategoryRequest: {
        name: result,
      },
    }).then((result) => {
      console.log(result);
    });
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>Modify categories</DialogTitle>
          <DialogDescription>
            <p>
              Drag and drop using the <Bars4Icon className="w-4 h-4 inline-block" /> handle on the left of the categories to reorder them.
            </p>
          </DialogDescription>
        </DialogHeader>

        <DragDropContext onDragEnd={onDragCategoryEnd}>
          <Droppable
            droppableId="order-guide-category-list"
            renderClone={(provided, _, rubric) => (
              <div
                {...provided.draggableProps}
                ref={provided.innerRef}
                className="p-1 flex flex-between gap-2 content-center items-center bg-white border-2 border-brand-500 overflow-hidden"
              >
                <div {...provided.dragHandleProps}>
                  <Bars4Icon />
                </div>
                <div className="p-2">
                  <h3>{categories[rubric.source.index].name}</h3>
                </div>
                <div className="flex-1" />

                <Button variant="icon" className="text-black">
                  <PencilIcon />
                </Button>
                <Button variant="icon">
                  <TrashIcon />
                </Button>
              </div>
            )}
          >
            {(categoryProvided) => (
              <div ref={categoryProvided.innerRef} {...categoryProvided.droppableProps} className="grid gap-2">
                {categories.map((category, categoryIndex) => (
                  <Draggable
                    disableInteractiveElementBlocking={true}
                    draggableId={`draggable-category-${getSlug(category)}`}
                    index={categoryIndex}
                    key={`draggable-category-${getSlug(category)}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        {...provided.draggableProps}
                        ref={provided.innerRef}
                        key={getSlug(category)}
                        className={twMerge(
                          "p-1 flex flex-between gap-2 content-center items-center",
                          snapshot.isDragging ? "bg-white border-2 border-brand-500 overflow-hidden" : "border border-dashed border-brand-500",
                        )}
                      >
                        <div {...provided.dragHandleProps}>
                          <Bars4Icon className="w-6 h-6" />
                        </div>
                        <div className="p-2">
                          <h3>{category.name}</h3>
                        </div>
                        <div className="flex-1" />

                        {/*<Button variant="icon" className="text-black">*/}
                        {/*  <PencilIcon className="w-6 h-6" />*/}
                        {/*</Button>*/}

                        {category.id === 1 ? (
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button variant="icon" disabled>
                                  <TrashIcon className="w-6 h-6 text-brand-500" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <div className="p-2">Cannot delete the default category</div>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        ) : (
                          <Button variant="icon" onClick={() => deleteCategory(category)}>
                            <TrashIcon className="w-6 h-6 text-brand-500" />
                          </Button>
                        )}
                      </div>
                    )}
                  </Draggable>
                ))}
                {categoryProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <Button variant="outline" className="w-full mt-4" onClick={createCategory}>
          <PlusIcon className="w-4 h-4 inline-block" />
          Add category
        </Button>

        <div className="flex justify-end gap-5 mt-5">
          <Button variant="link" onClick={() => setOpen(false)}>
            Cancel
          </Button>
          <Button variant="default" onClick={onSubmit}>
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
