import { OrderGuideTable } from "@/components/dashboard/order-guide/OrderGuideTable.tsx";
import { CreateOrderGuideDialog } from "@/components/dialogs/create-order-guide/CreateOrderGuideDialog.tsx";
import { UploadOrderGuide } from "@/components/order-guide-selection-widget/UploadOrderGuide.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent } from "@/components/ui/card.tsx";
import { useAppDispatch } from "@/store/Hooks.ts";
import { OrderGuideDto, useGetOrderGuideByIdQuery, useGetOrderGuideSummariesQuery } from "@/store/IRN.API.ts";
import { setCurrentOrderGuide } from "@/store/OrderGuide.slice.ts";
import { clearOrderGuideItems, orderGuideItemsLoaded } from "@/store/OrderGuideItem.slice.ts";
import { isProblemDetails } from "@/utils.ts";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const OrderGuidePage = () => {
  const { orderGuideId } = useParams();
  const orderGuideQuery = useGetOrderGuideByIdQuery(parseInt(orderGuideId ?? "0", 10));
  const orderGuides = useGetOrderGuideSummariesQuery();

  const [orderGuide, setOrderGuide] = useState<OrderGuideDto | null>(null);
  const [tableDisplayState, setTableDisplayState] = useState<"loading" | "error" | "visible">("loading");
  const appDispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(orderGuideQuery);
    if (orderGuideQuery.isLoading) {
      setTableDisplayState("loading");
    } else if (orderGuideQuery.error) {
      setTableDisplayState("error");
      const { data } = orderGuideQuery.error as any;
      if (data && isProblemDetails(data) && data.status === 401) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        appDispatch(setCurrentOrderGuide(undefined));
        appDispatch(clearOrderGuideItems());
        navigate("/app/order-guide");
      }
    } else if (orderGuideQuery.data) {
      setTableDisplayState("visible");
    } else {
      setTableDisplayState("error");
    }
  }, [orderGuideQuery.data, orderGuideQuery.error, orderGuideQuery.isLoading]);

  useEffect(() => {
    if (orderGuideQuery.isLoading) {
      appDispatch(setCurrentOrderGuide(undefined));
      appDispatch(clearOrderGuideItems());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orderGuideQuery.isLoading) {
      appDispatch(clearOrderGuideItems());
    }
  }, [appDispatch, orderGuideQuery.isLoading]);

  useEffect(() => {
    // console.log(orderGuideId);
    if (orderGuideQuery.data) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const guide: OrderGuideDto = { ...orderGuideQuery.data.orderGuide };

      setOrderGuide(guide);
      appDispatch(setCurrentOrderGuide(guide));
      appDispatch(orderGuideItemsLoaded(guide.items!));
    }
  }, [appDispatch, orderGuideQuery.data]);

  return (
    <>
      <div className="flex flex-col gap-10">
        <div className="w-full rounded bg-white shadow">
          {tableDisplayState === "loading" && (
            <div className="flex h-full w-full place-content-center place-items-center text-brand-600">
              <div
                className="inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
              </div>
            </div>
          )}

          {tableDisplayState === "error" && (
            <p>
              Error:{" "}
              {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                orderGuideQuery.error?.message ?? "Unknown error"
              }
            </p>
          )}
          {tableDisplayState === "visible" && <OrderGuideTable guide={orderGuide} />}
        </div>

        <div className="w-full text-center text-sm">
          <CreateOrderGuideDialog refetchSummaries={orderGuides.refetch}>
            <Button variant="link">+ Create another order guide</Button>
          </CreateOrderGuideDialog>
        </div>
        <Card className="bg-white">
          <CardContent>
            <UploadOrderGuide />
          </CardContent>
        </Card>
        <p className="container text-center text-sm text-gray-500">
          Note that prices are shown as estimates. Because we work with multiple distributors, your final price will be calculated at the time you place your
          order so we can calculate the most optimal order to save you the most money. The prices listed above are estimates given all the pricing information
          available as of right now. These prices are subject to change at any time.
        </p>
      </div>
    </>
  );
};
