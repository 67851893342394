import { PlaceholderImage } from "@/components/PlaceholderImage.tsx";
import { useGetProductMetadataQuery, useGetProductPriceHistoryQuery } from "@/store/IRN.API.ts";
import { LineChart } from "@tremor/react";
import { useParams } from "react-router-dom";

export function ProductInfoPage() {
  const { productId } = useParams<{ productId: string }>();
  const productQuery = useGetProductMetadataQuery({
    productId: parseInt(productId ?? "0", 10),
  });

  const priceHistory = useGetProductPriceHistoryQuery(parseInt(productId ?? "0", 10));

  return (
    <div className="flex flex-col gap-5 h-full">
      <h1 className="flex-grow-0 text-2xl">{productQuery.data?.product?.name}</h1>
      <p className="text-sm text-gray-500">{productQuery.data?.product?.brand}</p>
      <>
        {productQuery.data?.product?.thumbnailUrl ? (
          <img
            alt="Product image"
            className="aspect-square h-32 w-32 rounded-md object-cover md:h-[60px] md:w-[60px]"
            src={productQuery.data?.product?.thumbnailUrl ?? "/irn-square-240x240.png"}
          />
        ) : (
          <PlaceholderImage className="h-32 w-32" />
        )}
      </>
      <div className="text-sm grid gap-2">
        {/*<p>{productQuery.data?.description}</p>*/}
        <p>
          <strong>UPC:</strong> {productQuery.data?.product?.upc || "N/A"}
        </p>
        <p>
          <strong>Brand:</strong> {productQuery.data?.product?.brand || "N/A"}
        </p>
        <p>
          <strong>Category:</strong> {productQuery.data?.product?.categoryName || "N/A"}
        </p>
        <p>
          <strong>Manufacturer # (MPN):</strong> {productQuery.data?.product?.mpn || "N/A"}
        </p>
        <p>
          <strong>Unit Size:</strong> {productQuery.data?.product?.unitSize || "N/A"}
        </p>
      </div>

      <div>
        <LineChart
          data={
            priceHistory.data?.productPriceHistory?.data?.map((d) => {
              const otherData = Object.keys(d).reduce((acc: any, key: string) => {
                if (key !== "date") {
                  acc[key] = parseFloat(d[key]);
                } else {
                  acc[key] = d[key];
                }
                return acc;
              }, {});

              console.log(otherData); //eslint-disable-line no-console
              return otherData;
            }) || []
          }
          className="mt-4 h-72 w-full"
          index="date"
          categories={priceHistory.data?.productPriceHistory?.distributors ?? []}
          showAnimation
          connectNulls
          autoMinValue
          onValueChange={() => {}}
          curveType="monotone"
          colors={[
            "brand-500",
            "blue-700",
            "fuchsia-700",
            "green-700",
            "indigo-700",
            "lime-700",
            "orange-700",
            "pink-700",
            "purple-700",
            "red-700",
            "rose-700",
            "sky-700",
            "teal-700",
            "violet-700",
            "yellow-700",
          ]}
        />
      </div>
    </div>
  );
}
