import { cn } from "@/lib/utils.ts";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { OrderStatus, useGetRecentOrdersQuery } from "@/store/IRN.API.ts";
import { toCurrency } from "@/utils.ts";
import { CalendarIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";

const dayInMs = 1000 * 60 * 60 * 24;

export function RecentOrders() {
  const startOfDayTwoWeeksAgo = new Date(Date.now() - dayInMs * 14);
  startOfDayTwoWeeksAgo.setHours(0, 0, 0, 0);

  const activeBusiness = useAppSelector(selectActiveBusiness);
  const { data } = useGetRecentOrdersQuery({
    businessId: activeBusiness?.id ?? 0,
    take: 10,
  });

  const statusToString = (status: OrderStatus) => {
    switch (status) {
      case OrderStatus.Disputed:
        return "Disputed";
      case OrderStatus.NotPlaced:
        return "Not Placed";
      case OrderStatus.Refunded:
        return "Refunded";
      case OrderStatus.Returned:
        return "Returned";
      case OrderStatus.Shipped:
        return "Shipped";
      case OrderStatus.Delivered:
        return "Delivered";
      case OrderStatus.Pending:
        return "Pending";
      case OrderStatus.Cancelled:
        return "Cancelled";

      default:
        return "Unknown";
    }
  };

  // TODO: Get recent orders from the server
  /*const items = [
    {
      id: 1,
      businessName: "Westmont Diner",
      orderId: faker.finance.bic(),
      date: faker.date.recent({ days: 1 }).toLocaleDateString(),
      status: "Pending",
      total: 21551.65,
    },
    {
      id: 2,
      businessName: "Caffeine Express",
      orderId: faker.finance.bic(),
      date: faker.date.recent({ days: 7 }).toLocaleDateString(),
      status: "Delivered",
      total: 17193.12,
    },
    {
      id: 3,
      businessName: "A Really Really Long Name To Test Overflow Lorem Ipsum Dolor Sit Amet Consectetur Adipiscing Elite",
      orderId: faker.finance.bic(),
      date: faker.date.recent({ days: 14 }).toLocaleDateString(),
      status: "Delivered",
      total: 17193.12,
    },
  ];*/
  /*a:not(.btn) {*/
  /*    @apply text-brand-600 hover:text-red-700 hover:underline;*/
  /*}*/
  return (
    <div className="recent-orders">
      {!data?.recentOrders || data?.recentOrders.length === 0 ? (
        <div className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
          <div className="bg-white px-4 py-4">
            {/* You have no recent orders. <Link to="/app/order-guide">Create an order guide</Link> to get started. */}
            <p className="text-sm text-gray-500">
              You have no recent orders.{" "}
              <Link to="/app/order-guide" className="text-brand-600 hover:text-red-700 hover:underline">
                Create an order guide
              </Link>{" "}
              to get started.
            </p>
          </div>
        </div>
      ) : null}
      {data?.recentOrders && data.recentOrders.length > 0 && (
        <>
          <h2>Recent orders</h2>
          <ul role="list" className="space-y-3">
            {data.recentOrders.map((item) => (
              <li key={item.id} className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6">
                <div className="flex items-center justify-between">
                  <div className="whitespace-pre-wrap text-sm font-medium">
                    <Link to={`/app/orders/${item.id}`} className=" text-brand-600 hover:text-red-700 hover:underline hover:underline">
                      {item.business.name}
                    </Link>
                    <p className="text-xs text-gray-600"># {item.orderId}</p>
                  </div>
                  <div className="ml-2 flex flex-shrink-0">
                    <p
                      className={cn(
                        item.status === OrderStatus.Delivered ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800",
                        "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                      )}
                    >
                      {statusToString(item.status)}
                    </p>
                  </div>
                </div>
                <div className="mt-2 sm:flex sm:justify-between">
                  <div className="sm:flex">
                    <p className="flex items-center text-sm text-gray-500">
                      <CalendarIcon className="mr-1 h-5 w-5 flex-shrink-0 text-gray-400" />
                      {new Date(item.createdAt)?.toLocaleDateString("en-US")}
                    </p>
                  </div>
                  <div className="flex items-center text-sm text-gray-500 sm:mt-0">
                    {/*<CurrencyDollarIcon className="mr-1 h-5 w-5 flex-shrink-0 text-gray-500" />*/}
                    {toCurrency(item.total, false)}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
}
