import { IrnInput } from "@/components/forms/IrnInput.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog.tsx";
import { Form } from "@/components/ui/form.tsx";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { CreateDistributorRequest, UserInfoDto, useCreateDistributorMutation } from "@/store/IRN.API.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { ObjectSchema } from "yup";

const validationSchema: ObjectSchema<CreateDistributorRequest> = yup.object().shape({
  name: yup.string().required("Name is required"),
  minimumOrderAmount: yup.number().required("Minimum order amount is required"),
  minimumOrderQuantity: yup.number().required("Minimum order quantity is required"),
  servicesZipCodes: yup.array().of(yup.string().required()).required(),

  contactName: yup.string().required("Contact name is required"),
  contactEmail: yup.string().email().required("Contact email is required"),
  contactPhone: yup.string(),

  businessId: yup.number().required("Business ID is required"),
});

export function CreateDistributorDialog({
  open,
  setOpen,
  created,
  businessId,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  created: (distributorId: number) => void;
  businessId: number;
}) {
  const userInfo: UserInfoDto | undefined = useAppSelector(selectUserInfo);
  const [updateTrigger, updateResult] = useCreateDistributorMutation();

  console.log(businessId);
  const dispatch = useAppDispatch();
  const form = useForm<CreateDistributorRequest>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      businessId: businessId,
      minimumOrderAmount: 0,
      minimumOrderQuantity: 0,
      // Distinct zip codes
      servicesZipCodes:
        userInfo?.businesses
          ?.map((b) => b.address?.zipCode)
          .filter((v, i, a) => a.indexOf(v) === i)
          .filter((v) => v !== null) ?? [],
    },
  });

  useEffect(() => {
    if (updateResult.isSuccess) {
      setOpen(false);
      dispatch(showNotification({ message: "Distributor created", severity: "success" }));
      created(updateResult.data?.distributor?.id ?? 0);
    }
  }, [dispatch, updateResult.isSuccess]);

  const onSubmit = (data: CreateDistributorRequest) => {
    console.log(data);
    updateTrigger({
      ...data,
      businessId,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="max-h-screen max-w-xl overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>Create a new distributor</DialogTitle>
          <DialogDescription>Please provide the details listed below. Note that not all fields are required.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <IrnInput control={form.control} name="name" label="Name" description="The name of the distributor." />
            <IrnInput
              control={form.control}
              name="minimumOrderAmount"
              label="Minimum Order Amount"
              description="The minimum order amount for the distributor."
            />
            <IrnInput
              control={form.control}
              name="minimumOrderQuantity"
              label="Minimum Order Quantity"
              description="The minimum order quantity for the distributor."
            />
            <IrnInput
              control={form.control}
              name="servicesZipCodes"
              label="Services Zip Codes"
              description="The zip codes that the distributor services."
              multiple
            />
            <IrnInput control={form.control} name="contactName" label="Contact Name" description="The name of the distributor's contact." />
            <IrnInput control={form.control} name="contactEmail" label="Contact Email" description="The email of the distributor's contact." />
            <IrnInput control={form.control} name="contactPhone" label="Contact Phone" description="The phone of the distributor's contact." />

            <DialogFooter>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
