import { setAuthenticated, setUserInfo } from "@/store/Auth.Slice.ts";
import { setDebugObjectData } from "@/store/Dev.slice.ts";
import { useAppDispatch } from "@/store/Hooks.ts";
import { useGetUserQuery } from "@/store/IRN.API.ts";
import { AccountInfo } from "@azure/msal-browser";
import { useAccount } from "@azure/msal-react";
import * as Sentry from "@sentry/react";
import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

export function AuthHydration() {
  const account: AccountInfo | null = useAccount();

  const dispatch = useAppDispatch();
  const userInfo = useGetUserQuery();

  const posthog = usePostHog();

  // useEffect(() => {
  //   // console.log("AuthHydration", auth0.user);
  //   if (account) {
  //     Log.debug("User is authenticated 2", account);
  //   }
  // }, [account]);

  useEffect(() => {
    // console.log("AuthHydration", auth0.user);
    if (!account) {
      dispatch(setAuthenticated(null));
      return;
    }

    dispatch(
      setAuthenticated({
        name: account.name,
        username: account.username,
        environment: account.environment,
        tenantId: account.tenantId,
        localAccountId: account.localAccountId,
        idTokenClaims: account.idTokenClaims,
        idToken: account.idToken,
        nativeAccountId: account.homeAccountId,
        homeAccountId: account.homeAccountId,
        authorityType: account.authorityType,
      }),
    );
    // Log.debug("User is authenticated", account);
  }, [account, dispatch]);

  useEffect(() => {
    if (userInfo.data?.user) {
      dispatch(setUserInfo(userInfo.data.user));
      dispatch(
        setDebugObjectData({
          account: {
            name: account?.name,
            username: account?.username,
            environment: account?.environment,
            tenantId: account?.tenantId,
            localAccountId: account?.localAccountId,
            idTokenClaims: account?.idTokenClaims,
            idToken: account?.idToken,
            nativeAccountId: account?.homeAccountId,
            homeAccountId: account?.homeAccountId,
            authorityType: account?.authorityType,
          },
          userInfo: userInfo.data,
        }),
      );

      if (!userInfo || !account) {
        return;
      }

      posthog.identify(account.homeAccountId, {
        name: account.name,
        username: account.username,
        email: userInfo.data.user.email,
        roles: userInfo.data.user.roles,
        environment: account.environment,
        tenantId: account.tenantId,
        localAccountId: account.localAccountId,
        idTokenClaims: account.idTokenClaims,
        idToken: account.idToken,
        nativeAccountId: account.homeAccountId,
        authorityType: account.authorityType,
      });

      Sentry.setUser({
        email: userInfo.data.user.email ?? undefined,
        name: userInfo.data.user.firstName + " " + userInfo.data.user.lastName,
        username: account.username,
        environment: account.environment,
        tenantId: account.tenantId,
        localAccountId: account.localAccountId,
        idTokenClaims: account.idTokenClaims,
        idToken: account.idToken,
        nativeAccountId: account.homeAccountId,
        authorityType: account.authorityType,
      });
    }
  }, [account, dispatch, userInfo.data]);

  return null;
}
