import { OrderGuideSelectionWidget } from "@/components/order-guide-selection-widget/OrderGuideSelectionWidget.tsx";
import { OrderGuideActionButton } from "@/components/order-guide/OrderGuideActionButton.tsx";
import { OrderGuideListingCategoryWrapper } from "@/components/order-guide/OrderGuideListingCategoryWrapper.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { OrderGuideDto } from "@/store/IRN.API.ts";
import ShoppingCartIcon from "@heroicons/react/24/outline/ShoppingCartIcon";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function OrderGuideTableV2({ orderGuide }: { orderGuide: OrderGuideDto }) {
  const navigate = useNavigate();
  const [showReorderCategoriesDialog, setShowReorderCategoriesDialog] = useState(false);
  const [allowItemReordering, setAllowItemReordering] = useState(false);

  const renderPlaceOrderButton = (btnClass: string) => {
    return (
      <Button className={btnClass} onClick={() => navigate("./order", { relative: "path" })}>
        <ShoppingCartIcon className="mr-2 h-5 w-5" />
        Place order
      </Button>
    );
  };

  return (
    <Card className="mb-10 border-none bg-white shadow-none">
      <CardHeader className="flex flex-row flex-wrap items-center justify-between gap-4 space-y-0 md:justify-normal">
        <CardTitle>
          <div className="pe-4 md:pe-0">
            <OrderGuideSelectionWidget compact />
          </div>
        </CardTitle>

        {renderPlaceOrderButton("mr-4 md:ml-auto")}
        {/*<Button className="mr-4" size="icon" variant="icon">*/}
        {/*  <DownloadIcon className="h-6 w-6" />*/}
        {/*</Button>*/}
        {/* A "3 dot" button to show more actions to be taken on this order guide */}
        <OrderGuideActionButton
          orderGuide={orderGuide}
          showReorderCategoriesDialog={showReorderCategoriesDialog}
          setShowReorderCategoriesDialog={setShowReorderCategoriesDialog}
          allowItemReordering={allowItemReordering}
          setAllowItemReordering={setAllowItemReordering}
        />
      </CardHeader>
      <CardContent>
        <OrderGuideListingCategoryWrapper orderGuideId={orderGuide.id} allowItemReordering={allowItemReordering} />

        <div className="mt-5 w-full text-right text-gray-500">
          <p>
            Estimated total:{" "}
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(orderGuide.items.reduce((acc, item) => acc + item.quantity * (item.productMetadata?.products?.[0]?.price ?? 0), 0))}
          </p>
          <p>Total items in next order: {orderGuide.items.reduce((acc, item) => acc + item.quantity, 0)}</p>
          <div>{renderPlaceOrderButton("mt-5")}</div>
        </div>
      </CardContent>
    </Card>
  );
}
