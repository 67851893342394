import { AppSidebar } from "@/components/AppSidebar.tsx";
import { SidebarProvider, SidebarTrigger } from "@/components/ui/sidebar.tsx";
import { ChartPieIcon, ClipboardDocumentCheckIcon, CurrencyDollarIcon, HomeIcon, QuestionMarkCircleIcon, ShoppingCartIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { Outlet, useMatches } from "react-router-dom";

const navigationOptions = [
  { name: "Dashboard", href: "/app/dashboard", icon: HomeIcon, current: true },
  { name: "Products", href: "/app/products", icon: ShoppingCartIcon, current: false },
  { name: "Order Guides", href: "/app/order-guide", icon: ClipboardDocumentCheckIcon, current: false },
  { name: "My Orders", href: "/app/orders", icon: CurrencyDollarIcon, current: false },
  { name: "Reports", href: "#", icon: ChartPieIcon, current: false },
  { name: "Support", href: "https://help.irnworks.com", icon: QuestionMarkCircleIcon, current: false, external: true },
  { name: "Rebates", href: "/app/rebates", icon: CurrencyDollarIcon, current: false },
  // { name: "Resources", href: "/app/resources", current: false },
];

// const teams = [
//   { id: 1, name: "Local Development", href: "#", initial: "L", current: true },
//   { id: 2, name: "Test 1", href: "#", initial: "T", current: false },
//   { id: 3, name: "Test 2", href: "#", initial: "T", current: false },
//   { id: 3, name: "Upload Test", href: "#", initial: "U", current: false },
// ];

export function MainSidebarLayout() {
  // const [sidebarOpen, setSidebarOpen] = useState(false);
  const matches = useMatches();
  const [, setNavigation] = useState(navigationOptions);
  // const dispatch = useAppDispatch();
  // const userInfo = useAppSelector(selectUserInfo);
  // const businesses = useAppSelector(selectBusinesses);
  // const activeBusiness = useAppSelector(selectActiveBusiness);

  // const client = getClient<BrowserClient>();
  // const feedback = client?.getIntegrationByName<Feedback>("Feedback");

  useEffect(() => {
    const newNavigation = navigationOptions.map((item) => {
      const newItem = { ...item };
      newItem.current = matches.some((match) => match.pathname.includes(item.href) || match.pathname === item.href);
      return newItem;
    });
    setNavigation(newNavigation);
  }, [matches]);

  // const getCurrentRouteTitle = () => {
  //   const currentRoute = matches[matches.length - 1].handle as any;
  //   if (currentRoute?.title) {
  //     return currentRoute.title.toString();
  //   }
  //   return "IRN";
  // };

  useEffect(() => {}, [matches]);

  return (
    <>
      <SidebarProvider>
        <AppSidebar />
        <main className="w-full">
          <SidebarTrigger />
          <div className="p-10">
            <Outlet />
          </div>
        </main>
      </SidebarProvider>

      {/*/!**/}
      {/*  This example requires updating your template:*/}

      {/*  ```*/}
      {/*  <html class="h-full bg-white">*/}
      {/*  <body class="h-full">*/}
      {/*  ```*/}
      {/**!/*/}
      {/*<div>*/}
      {/*  <Transition.Root show={sidebarOpen} as={Fragment}>*/}
      {/*    <Dialog className="relative z-50 lg:hidden" onClose={setSidebarOpen}>*/}
      {/*      <Transition.Child*/}
      {/*        as={Fragment}*/}
      {/*        enter="transition-opacity ease-linear duration-300"*/}
      {/*        enterFrom="opacity-0"*/}
      {/*        enterTo="opacity-100"*/}
      {/*        leave="transition-opacity ease-linear duration-300"*/}
      {/*        leaveFrom="opacity-100"*/}
      {/*        leaveTo="opacity-0"*/}
      {/*      >*/}
      {/*        <div className="fixed inset-0 bg-gray-900/80" />*/}
      {/*      </Transition.Child>*/}

      {/*      <div className="fixed inset-0 flex">*/}
      {/*        <Transition.Child*/}
      {/*          as={Fragment}*/}
      {/*          enter="transition ease-in-out duration-300 transform"*/}
      {/*          enterFrom="-translate-x-full"*/}
      {/*          enterTo="translate-x-0"*/}
      {/*          leave="transition ease-in-out duration-300 transform"*/}
      {/*          leaveFrom="translate-x-0"*/}
      {/*          leaveTo="-translate-x-full"*/}
      {/*        >*/}
      {/*          <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">*/}
      {/*            <Transition.Child*/}
      {/*              as={Fragment}*/}
      {/*              enter="ease-in-out duration-300"*/}
      {/*              enterFrom="opacity-0"*/}
      {/*              enterTo="opacity-100"*/}
      {/*              leave="ease-in-out duration-300"*/}
      {/*              leaveFrom="opacity-100"*/}
      {/*              leaveTo="opacity-0"*/}
      {/*            >*/}
      {/*              <div className="absolute left-full top-0 flex w-16 justify-center pt-5">*/}
      {/*                <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>*/}
      {/*                  <span className="sr-only">Close sidebar</span>*/}
      {/*                  <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />*/}
      {/*                </button>*/}
      {/*              </div>*/}
      {/*            </Transition.Child>*/}
      {/*            /!* Sidebar component, swap this element with another sidebar if you like *!/*/}
      {/*            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">*/}
      {/*              <div className="flex h-16 shrink-0 items-center">*/}
      {/*                /!*<img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=brand&shade=600" alt="Your Company" />*!/*/}

      {/*                <Logo className="h-8 w-auto" />*/}
      {/*              </div>*/}
      {/*              <nav className="flex flex-1 flex-col">*/}
      {/*                <ul role="list" className="flex flex-1 flex-col gap-y-7">*/}
      {/*                  <li>*/}
      {/*                    <ul role="list" className="-mx-2 space-y-1">*/}
      {/*                      {navigation.map((item) => (*/}
      {/*                        <li key={item.name}>*/}
      {/*                          <Link*/}
      {/*                            to={item.href}*/}
      {/*                            onClick={() => setSidebarOpen(false)}*/}
      {/*                            className={twMerge(*/}
      {/*                              item.current ? "bg-gray-50 text-brand-600" : "text-gray-700 hover:text-brand-600 hover:bg-gray-50",*/}
      {/*                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",*/}
      {/*                            )}*/}
      {/*                          >*/}
      {/*                            <item.icon*/}
      {/*                              className={twMerge(item.current ? "text-brand-600" : "text-gray-400 group-hover:text-brand-600", "h-6 w-6 shrink-0")}*/}
      {/*                              aria-hidden="true"*/}
      {/*                            />*/}
      {/*                            {item.name}*/}
      {/*                          </Link>*/}
      {/*                        </li>*/}
      {/*                      ))}*/}
      {/*                    </ul>*/}
      {/*                  </li>*/}
      {/*                  <li>*/}
      {/*                    <div className="text-xs font-semibold leading-6 text-gray-400">Your businesses</div>*/}
      {/*                    <ul role="list" className="-mx-2 mt-2 space-y-1">*/}
      {/*                      {businesses.map((team) => (*/}
      {/*                        <li key={team.name}>*/}
      {/*                          <button*/}
      {/*                            onClick={() => {*/}
      {/*                              dispatch(setActiveBusiness(team));*/}
      {/*                              setSidebarOpen(false);*/}
      {/*                            }}*/}
      {/*                            className={twMerge(*/}
      {/*                              team.id == activeBusiness?.id ? "bg-gray-50 text-brand-600" : "text-gray-700 hover:text-brand-600 hover:bg-gray-50",*/}
      {/*                              "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full",*/}
      {/*                            )}*/}
      {/*                          >*/}
      {/*                            <span*/}
      {/*                              className={twMerge(*/}
      {/*                                team.id == activeBusiness?.id*/}
      {/*                                  ? "text-brand-600 border-brand-600"*/}
      {/*                                  : "text-gray-400 border-gray-200 group-hover:border-brand-600 group-hover:text-brand-600",*/}
      {/*                                "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white",*/}
      {/*                              )}*/}
      {/*                            >*/}
      {/*                              {team.name.charAt(0)}*/}
      {/*                            </span>*/}
      {/*                            <span className="truncate">{team.name}</span>*/}
      {/*                          </button>*/}
      {/*                        </li>*/}
      {/*                      ))}*/}
      {/*                    </ul>*/}
      {/*                  </li>*/}
      {/*                </ul>*/}
      {/*              </nav>*/}
      {/*            </div>*/}
      {/*          </Dialog.Panel>*/}
      {/*        </Transition.Child>*/}
      {/*      </div>*/}
      {/*    </Dialog>*/}
      {/*  </Transition.Root>*/}

      {/*  /!* Static sidebar for desktop *!/*/}
      {/*  <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">*/}
      {/*    /!* Sidebar component, swap this element with another sidebar if you like *!/*/}
      {/*    <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">*/}
      {/*      <div className="flex h-16 shrink-0 items-center justify-center">*/}
      {/*        <Logo className="h-8 w-auto" />*/}
      {/*        /!*<img className="h-8 w-auto" src="https://tailwindui.com/img/logos/mark.svg?color=brand&shade=600" alt="Your Company" />*!/*/}
      {/*      </div>*/}
      {/*      <nav className="flex flex-1 flex-col">*/}
      {/*        <ul role="list" className="flex flex-1 flex-col gap-y-7">*/}
      {/*          <li>*/}
      {/*            <ul role="list" className="-mx-2 space-y-1">*/}
      {/*              {navigation.map((item) => (*/}
      {/*                <li key={item.name}>*/}
      {/*                  <Link*/}
      {/*                    to={item.href}*/}
      {/*                    className={twMerge(*/}
      {/*                      item.current ? "bg-gray-50 text-brand-600" : "text-gray-700 hover:text-brand-600 hover:bg-gray-50",*/}
      {/*                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",*/}
      {/*                    )}*/}
      {/*                  >*/}
      {/*                    <item.icon*/}
      {/*                      className={twMerge(item.current ? "text-brand-600" : "text-gray-400 group-hover:text-brand-600", "h-6 w-6 shrink-0")}*/}
      {/*                      aria-hidden="true"*/}
      {/*                    />*/}
      {/*                    {item.name}*/}
      {/*                  </Link>*/}
      {/*                </li>*/}
      {/*              ))}*/}
      {/*            </ul>*/}
      {/*          </li>*/}
      {/*          <li>*/}
      {/*            <div className="text-xs font-semibold leading-6 text-gray-400">Your businesses</div>*/}
      {/*            <ul role="list" className="-mx-2 mt-2 space-y-1">*/}
      {/*              {businesses.map((team) => (*/}
      {/*                <li key={team.name}>*/}
      {/*                  <button*/}
      {/*                    onClick={() => {*/}
      {/*                      dispatch(setActiveBusiness(team));*/}
      {/*                    }}*/}
      {/*                    className={twMerge(*/}
      {/*                      team.id == activeBusiness?.id ? "bg-gray-50 text-brand-600" : "text-gray-700 hover:text-brand-600 hover:bg-gray-50",*/}
      {/*                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold w-full",*/}
      {/*                    )}*/}
      {/*                  >*/}
      {/*                    <span*/}
      {/*                      className={twMerge(*/}
      {/*                        team.id == activeBusiness?.id*/}
      {/*                          ? "text-brand-600 border-brand-600"*/}
      {/*                          : "text-gray-400 border-gray-200 group-hover:border-brand-600 group-hover:text-brand-600",*/}
      {/*                        "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white",*/}
      {/*                      )}*/}
      {/*                    >*/}
      {/*                      {team.name.charAt(0)}*/}
      {/*                    </span>*/}
      {/*                    <span className="truncate">{team.name}</span>*/}
      {/*                  </button>*/}
      {/*                </li>*/}
      {/*              ))}*/}
      {/*            </ul>*/}
      {/*          </li>*/}
      {/*          <li className="mt-auto">*/}
      {/*            <a*/}
      {/*              href="#"*/}
      {/*              onClick={async () => {*/}
      {/*                if (feedback) {*/}
      {/*                  feedback.openDialog();*/}
      {/*                }*/}
      {/*              }}*/}
      {/*              className={twMerge(*/}
      {/*                "text-gray-700 hover:text-brand-600 hover:bg-gray-50",*/}
      {/*                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold",*/}
      {/*              )}*/}
      {/*            >*/}
      {/*              <span*/}
      {/*                className={twMerge(*/}
      {/*                  "text-gray-400 border-gray-200 group-hover:border-brand-600 group-hover:text-brand-600",*/}
      {/*                  "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white",*/}
      {/*                )}*/}
      {/*              >*/}
      {/*                <BugAntIcon className="h-6 w-6" />*/}
      {/*              </span>*/}
      {/*              <span className="truncate">Report a bug</span>*/}
      {/*            </a>*/}
      {/*          </li>*/}
      {/*          <li className="-mx-6">*/}
      {/*            <Link to="/app/user/profile" className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50">*/}
      {/*              <img*/}
      {/*                className="h-8 w-8 rounded-full"*/}
      {/*                src={`https://api.dicebear.com/8.x/initials/svg?seed=${userInfo?.firstName?.charAt(0)}${userInfo?.lastName?.charAt(0)}`}*/}
      {/*                alt=""*/}
      {/*              />*/}
      {/*              <span className="sr-only">Your profile</span>*/}
      {/*              <span aria-hidden="true">*/}
      {/*                {userInfo?.firstName} {userInfo?.lastName}*/}
      {/*              </span>*/}
      {/*            </Link>*/}
      {/*          </li>*/}
      {/*        </ul>*/}
      {/*      </nav>*/}
      {/*    </div>*/}
      {/*  </div>*/}

      {/*  <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">*/}
      {/*    <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>*/}
      {/*      <span className="sr-only">Open sidebar</span>*/}
      {/*      <Bars3Icon className="h-6 w-6" aria-hidden="true" />*/}
      {/*    </button>*/}
      {/*    <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">{getCurrentRouteTitle()}</div>*/}
      {/*    <Link to="/app/user/profile">*/}
      {/*      <span className="sr-only">Your profile</span>*/}
      {/*      <img*/}
      {/*        className="h-8 w-8 rounded-full"*/}
      {/*        src={`https://api.dicebear.com/8.x/initials/svg?seed=${userInfo?.firstName?.charAt(0)}${userInfo?.lastName?.charAt(0)}`}*/}
      {/*        alt=""*/}
      {/*      />*/}
      {/*    </Link>*/}
      {/*  </div>*/}

      {/*  <main className="sm:py-10 lg:pl-72">*/}
      {/*    <div className="px-4 sm:px-6 lg:px-8">*/}
      {/*      <Outlet />*/}
      {/*    </div>*/}
      {/*  </main>*/}
      {/*</div>*/}
    </>
  );
}
