import { SearchFacet, SearchFacetDto } from "@/store/IRN.API.ts";
import { Disclosure } from "@headlessui/react";
import { MinusIcon } from "@heroicons/react/20/solid";
import { PlusIcon } from "@heroicons/react/24/solid";
import { SearchFilter } from "./SearchFilter.ts";

export function LargeFormatProductFilter(props: {
  searchFilters: SearchFilter[];
  activeSearchFacets: SearchFacetDto[];
  setActiveSearchFacets: (facets: SearchFacetDto[]) => void;
}) {
  const checkFacet = (facetName: SearchFacet, facetValue: string, checked: boolean) => {
    const filter = props.searchFilters.find((f) => f.id === facetName);
    if (filter) {
      filter.options.forEach((option) => {
        if (option.value === facetValue) {
          if (checked) {
            option.checked = true;

            if (!props.activeSearchFacets.find((f) => f.facet === facetName && f.value === facetValue)) {
              props.setActiveSearchFacets([...props.activeSearchFacets, { facet: facetName, value: facetValue }]);
            }
          } else {
            option.checked = false;

            props.setActiveSearchFacets(props.activeSearchFacets.filter((f) => f.facet !== facetName || f.value !== facetValue));
          }
        }
      });
    }
  };
  return (
    <div className="rounded bg-white shadow w-full p-5">
      {/* Filters */}
      <h2 className="">Filter</h2>
      <div className="divide-y">
        {props.searchFilters.map((section) => (
          <Disclosure as="div" key={section.id} className="py-6 last:pb-0" defaultOpen={true}>
            {({ open }) => (
              <>
                <h3 className="-my-3 flow-root">
                  <Disclosure.Button className="flex w-full items-center justify-between bg-white py-3 text-sm text-gray-400 hover:text-gray-500">
                    <span className="font-medium text-gray-900">{section.name}</span>
                    <span className="ml-6 flex items-center">
                      {open ? <MinusIcon className="h-5 w-5" aria-hidden="true" /> : <PlusIcon className="h-5 w-5" aria-hidden="true" />}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="pt-6">
                  <div className="max-h-96 space-y-4 overflow-y-auto pl-1">
                    {section.options.map((option, optionIdx) => (
                      <div key={option.value! + optionIdx} className="flex items-center">
                        <input
                          id={`filter-${section.id}-${optionIdx}`}
                          name={`${section.id}[]`}
                          defaultValue={option.value}
                          type="checkbox"
                          defaultChecked={option.checked}
                          className="h-4 w-4 rounded border-gray-300 text-brand-600 focus:ring-brand-500"
                          onChange={(e) => {
                            checkFacet(section.id, option.value!, e.target.checked);
                          }}
                        />
                        <label htmlFor={`filter-${section.id}-${optionIdx}`} className="ml-3 text-sm text-gray-600">
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>

      {/* Product grid */}
      <div className="lg:col-span-3">{/* Your content */}</div>
    </div>
  );
}
