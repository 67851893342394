import { addressFormValidationSchema } from "@/components/forms/fields/AddressFormValidation.tsx";
import { BusinessRegistrationType, CreateOrUpdateBusinessRequest } from "@/store/IRN.API.ts";
import { isValidPhoneNumber } from "react-phone-number-input/min";
import { ObjectSchema, object, string } from "yup";

export const businessFormValidation: ObjectSchema<CreateOrUpdateBusinessRequest> = object().shape({
  name: string().required(),
  taxId: string().matches(/^\d{9}$/, { message: "Please enter a valid tax ID", excludeEmptyString: true }),
  careOf: string(),

  // address1: string().required("Address is required"),
  // address2: string().optional(),
  // city: string().required("City is required"),
  // state: string()
  //   .required("State is required")
  //   .matches(/^[A-Z]{2}$/, "Please enter a valid state"),
  // zipCode: string()
  //   .required("Zip code is required")
  //   .matches(/^[0-9]{5}(-[0-9]{4})?$/, "Please enter a valid zip code"),

  email: string().required().email(),

  phoneNumber: string()
    .required()
    .test("is-valid-phone-number", "Please enter a valid phone number", (value) => !value || isValidPhoneNumber(value)),
  operatingName: string().required("Operating name is required"),
  registrationType: string().required().oneOf(Object.values(BusinessRegistrationType)),

  address: addressFormValidationSchema,
});

export const businessFormValidationRequireAllFields: ObjectSchema<CreateOrUpdateBusinessRequest> = object().shape({
  name: string().required(),
  taxId: string()
    .matches(/^\d{9}$/, { message: "Please enter a valid tax ID", excludeEmptyString: true })
    .required(),
  careOf: string(),

  // address1: string().required("Address is required"),
  // address2: string().optional(),
  // city: string().required("City is required"),
  // state: string()
  //   .required("State is required")
  //   .matches(/^[A-Z]{2}$/, "Please enter a valid state"),
  // zipCode: string()
  //   .required("Zip code is required")
  //   .matches(/^[0-9]{5}(-[0-9]{4})?$/, "Please enter a valid zip code"),

  email: string().email().required(),

  phoneNumber: string()
    .matches(/^\d{10}$/, { message: "Please enter a valid phone number", excludeEmptyString: true })
    .required(),
  operatingName: string().required("Operating name is required"),
  registrationType: string().oneOf(Object.values(BusinessRegistrationType)).required(),

  address: addressFormValidationSchema,
});
