import { OrderGuideItemListingActionButton } from "@/components/order-guide/OrderGuideItemListingActionButton.tsx";
import { OrderGuideItemListingImage } from "@/components/order-guide/OrderGuideItemListingImage.tsx";
import { OrderGuideItemListingItemInfoBlock } from "@/components/order-guide/OrderGuideItemListingItemInfoBlock.tsx";
import { OrderGuideItemListingParControls } from "@/components/order-guide/OrderGuideItemListingParControls.tsx";
import { OrderGuideItemListingQuantityControl } from "@/components/order-guide/OrderGuideItemListingQuantityControl.tsx";
import { PriceDisplay } from "@/components/ui/price-display.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { OrderGuideDisplayDto, OrderGuideType, useSetProductQuantityInOrderGuideMutation } from "@/store/IRN.API.ts";
import { selectEnableParEditing } from "@/store/OrderGuide.slice.ts";
import { orderGuideItemsUpdated, selectOrderGuideItem } from "@/store/OrderGuideItem.slice.ts";
import { Draggable } from "@hello-pangea/dnd";
import { Bars4Icon } from "@heroicons/react/24/outline";
import { useDebounce } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";

// interface DragItem {
//   index: number;
//   item: OrderGuideItemDto;
//   type: string;
// }

export function OrderGuideItemListing({
  orderGuide,
  itemId,
  index,
  canDrag,
}: {
  orderGuide: OrderGuideDisplayDto;
  itemId: number;
  index: number;
  canDrag: boolean;
}) {
  const parEditingEnabled = useAppSelector(selectEnableParEditing);

  const item = useAppSelector(selectOrderGuideItem(itemId))!;

  // console.log("item", item.quantity, item.par, item.onHand, item.id, item.productLink?.products?.[0].name);

  const appDispatch = useAppDispatch();

  const [updateQuantityTrigger] = useSetProductQuantityInOrderGuideMutation();

  const [totalPrice, setTotalPrice] = useState<number>(0 /*(item.productLink?.products?.[0].price || 0) * item.quantity*/);
  const [quantity, setQuantity] = useState<number>(item?.quantity || 0);
  const [par, setPar] = useState<number | undefined | null>(item?.par || 0);
  const [onHand, setOnHand] = useState<number | undefined | null>(item?.onHand);

  // Since the input is controlled, we need to debounce the value to prevent unnecessary API calls
  const debouncedQuantity = useDebounce(quantity, 250);
  const debouncedPar = useDebounce(par, 250);
  const debouncedOnHand = useDebounce(onHand, 250);

  // When the par editing mode changes, update the total price
  useEffect(() => {
    // Update the total price whenever the quantity changes. No debouncing.
    setTotalPrice(quantity * (item.productMetadata?.products?.[0]?.price || 0));
  }, [quantity, parEditingEnabled]);

  useEffect(() => {
    updateQuantity(debouncedQuantity);
  }, [debouncedQuantity]);

  useEffect(() => {
    updatePar(debouncedPar);
  }, [debouncedPar]);

  useEffect(() => {
    updateOnHand(debouncedOnHand);
  }, [debouncedOnHand]);

  function updateQuantity(value: number) {
    // console.log("Updating quantity", value, item.id, item.productLink!.id!);
    if (orderGuide.type === OrderGuideType.Quantity) {
      setQuantity(value);
      updateQuantityTrigger({
        orderGuideId: orderGuide!.id,
        productId: item.productMetadata!.id!,
        setProductQuantityInOrderGuideRequest: {
          quantity: value,
        },
      });

      appDispatch(
        orderGuideItemsUpdated({
          id: item.id!,
          changes: {
            quantity: value,
          },
        }),
      );
    }
  }

  // const debouncedUpdatePar = useDebounce(updatePar, 500);

  function updateOnHand(value: number | null | undefined) {
    // console.log("Updating onHand", value, item.onHand, item.id, item.productLink!.name);
    if (orderGuide.type === OrderGuideType.Par && value !== item.onHand) {
      setOnHand(value);
      updateQuantityTrigger({
        orderGuideId: orderGuide!.id,
        productId: item.productMetadata!.id!,
        setProductQuantityInOrderGuideRequest: {
          onHand: value,
        },
      });

      appDispatch(
        orderGuideItemsUpdated({
          id: item.id!,
          changes: {
            onHand: value,
            quantity: (item.par ?? 0) - (value ?? 0),
          },
        }),
      );
    }
  }

  function updatePar(value: number | null | undefined) {
    // console.log("Updating par", value, item.par, item.id, item.productLink!.name);
    if (orderGuide.type === OrderGuideType.Par && parEditingEnabled && value !== item.par) {
      setPar(value);

      updateQuantityTrigger({
        orderGuideId: orderGuide!.id,
        productId: item.productMetadata!.id!,
        setProductQuantityInOrderGuideRequest: {
          par: value,
        },
      });

      appDispatch(
        orderGuideItemsUpdated({
          id: item.id!,
          changes: {
            par: value,
          },
        }),
      );
    }
  }

  const renderQuantityControls = () => {
    return (
      <div className="mt-4 flex justify-between gap-4 rounded px-2 md:mt-0">
        {orderGuide.type === OrderGuideType.Par && (
          <OrderGuideItemListingParControls
            parEditingEnabled={parEditingEnabled}
            item={item}
            onParChange={(e) => {
              console.log("par", e.target.value);
              setPar(parseInt(e.target.value, 10));
            }}
            quantity={quantity}
            onOnHandChange={(e) => {
              console.log("onHand", e.target.value);
              setOnHand(parseInt(e.target.value, 10));
              setQuantity((item.par ?? 0) - parseInt(e.target.value, 10));
            }}
          />
        )}

        {orderGuide.type === OrderGuideType.Quantity && (
          <OrderGuideItemListingQuantityControl item={item} onChange={(e) => updateQuantity(parseInt(e.target.value, 10))} />
        )}
      </div>
    );
  };

  if (!item) {
    return null;
  }

  // ref={ref} style={{ opacity }} data-handler-id={handlerId}
  return (
    <Draggable draggableId={`ogi-${item.id}`} index={index} isDragDisabled={!canDrag}>
      {(provided, snapshot) => (
        <div
          // key={item.id}
          {...provided.draggableProps}
          ref={provided.innerRef}
          className={twMerge(
            "flex-col gap-4 py-4 md:flex-row md:justify-between",
            snapshot.isDragging ? "bg-white border-2 border-brand-500 rounded-lg px-5" : "bg-white",
          )}
        >
          <div className="flex gap-5 align-middle">
            {canDrag && (
              <div {...provided.dragHandleProps} className="my-auto">
                <Bars4Icon className="w-6 h-6" />
              </div>
            )}
            <OrderGuideItemListingImage item={item} />
            <OrderGuideItemListingItemInfoBlock item={item} price={totalPrice} />
            <div className="hidden lg:block">{renderQuantityControls()}</div>
            <div className="min-w-[10ch] text-right md:text-left">
              <p className="text-xs">Total</p>
              <PriceDisplay price={totalPrice} size="md" className="text-brand-900" />
            </div>
            <OrderGuideItemListingActionButton item={item} orderGuide={orderGuide} />
          </div>
          <div className="md:flex-1"></div>
          <div className="lg:hidden">{renderQuantityControls()}</div>
        </div>
      )}
    </Draggable>
  );
}
