import { useConfirm, usePrompt } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { ReorderOrderGuideCategoriesDialog } from "@/components/dialogs/reorder-order-guide-categories/ReorderOrderGuideCategoriesDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { Label } from "@/components/ui/label.tsx";
import { Switch } from "@/components/ui/switch.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { OrderGuideDto, OrderGuideType, useChangeOrderGuideTypeMutation, useDeleteOrderGuideMutation, useUpdateOrderGuideMutation } from "@/store/IRN.API.ts";
import { selectEnableParEditing, setEnableParEditing } from "@/store/OrderGuide.slice.ts";
import { orderGuideManyItemsUpdated } from "@/store/OrderGuideItem.slice.ts";
import { ClipboardDocumentListIcon, TagIcon } from "@heroicons/react/24/outline";
import { ClipboardEdit, MoreVertical, Pencil, Trash } from "lucide-react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

type Props = {
  orderGuide: OrderGuideDto;
  showReorderCategoriesDialog: boolean;
  setShowReorderCategoriesDialog: (show: boolean) => void;

  allowItemReordering: boolean;
  setAllowItemReordering: (allow: boolean) => void;
};

export function OrderGuideActionButton({
  orderGuide,
  setShowReorderCategoriesDialog,
  showReorderCategoriesDialog,
  setAllowItemReordering,
  allowItemReordering,
}: Props) {
  const [changeOrderGuideTypeTrigger] = useChangeOrderGuideTypeMutation();
  const [deleteOrderGuideTrigger, deleteOrderGuideResult] = useDeleteOrderGuideMutation();
  const [updateOrderGuideTrigger] = useUpdateOrderGuideMutation();
  const parEditingEnabled = useAppSelector(selectEnableParEditing);
  const confirmationDialog = useConfirm();
  const inputDialog = usePrompt();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (deleteOrderGuideResult.isSuccess) {
      navigate("/app/order-guide");
    }
  }, [deleteOrderGuideResult.isSuccess, navigate]);

  const renameOrderGuide = async () => {
    const newName = await inputDialog({
      title: "Rename Order Guide",
      body: "Enter the new name for this order guide",
      inputProps: {
        defaultValue: orderGuide?.name,
      },
    });

    if (newName) {
      updateOrderGuideTrigger({
        orderGuideId: orderGuide!.id,
        updateOrderGuideRequest: {
          name: newName,
        },
      });
    }
  };

  async function toggleOrderGuideMode() {
    const confirmed = await confirmationDialog({
      title: "Switch Order Guide Mode",
      body: (
        <div className="flex flex-col gap-5">
          <p className="font-semibold">Are you sure you want to switch to {orderGuide?.type === OrderGuideType.Quantity ? "PAR" : "Quantity"} mode?</p>
          {orderGuide?.type === OrderGuideType.Quantity ? (
            <>
              <p>
                PAR (Periodic Automatic Replacement) mode will allow you to set required stock levels, and our system will automatically determine the quantity
                to order based on your <span className="font-semibold">current</span> stock levels. You will need to ensure that you have set the correct PAR
                levels for each item.
              </p>
              <p>
                PAR ordering is recommended for items that you want to keep in stock at all times, such as staple items or items that are used frequently. This
                mode will also simplify the ordering process by automatically calculating the quantities to order based on your stock levels.
              </p>
            </>
          ) : (
            <>
              <p>
                Quantity mode will allow you to set the exact quantity to order for each item. This mode is recommended for items that you want to order a
                specific quantity of, regardless of your stock levels.
              </p>
              <p>
                IRN suggests using Quantity mode for items that are not used frequently, or for items that you want to order a specific quantity of, regardless
                of your stock levels. Most restaurants use Quantity mode for one-time or special order items.
              </p>
            </>
          )}

          <p className="rounded border border-destructive bg-destructive/20 p-3 font-semibold text-destructive">All quantities will be reset to 0</p>
        </div>
      ),
      actionButton: "Switch",
      actionVariant: "default",
      cancelButton: "Cancel",
      cancelVariant: "link",
    });

    if (confirmed) {
      changeOrderGuideTypeTrigger({
        orderGuideId: orderGuide!.id,
        changeOrderGuideTypeRequest: {
          type: orderGuide!.type === OrderGuideType.Quantity ? OrderGuideType.Par : OrderGuideType.Quantity,
        },
      });

      // By default, enable par editing mode when switching to par mode
      if (orderGuide?.type === OrderGuideType.Quantity) {
        dispatch(setEnableParEditing(true));
      }

      // Reset all the entities in the order guide
      dispatch(
        orderGuideManyItemsUpdated(
          orderGuide.items.map((item) => ({
            id: item.id,
            changes: {
              quantity: 0,
              par: 0,
              onHand: 0,
            },
          })),
        ),
      );
    }
  }

  const deleteOrderGuide = async () => {
    const confirmed = await confirmationDialog({
      title: "Delete Order Guide",
      body: (
        <div className="flex flex-col gap-5">
          <p>Are you sure you want to delete this order guide?</p>
          <p className="font-semibold text-destructive">This action cannot be undone.</p>
        </div>
      ),
      actionButton: "Delete",
      actionVariant: "destructive",
      cancelButton: "Cancel",
      cancelVariant: "link",
    });

    if (confirmed) {
      deleteOrderGuideTrigger(orderGuide!.id);
    }
  };

  return (
    <>
      <ReorderOrderGuideCategoriesDialog orderGuide={orderGuide} open={showReorderCategoriesDialog} setOpen={setShowReorderCategoriesDialog} />
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="-mx-2 px-2" onClick={() => navigate("./order", { relative: "path" })}>
            <MoreVertical className="h-5 w-5" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56">
          <DropdownMenuLabel>Edit Order Guide</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {orderGuide?.type === OrderGuideType.Par && (
            <>
              <DropdownMenuItem onClick={() => dispatch(setEnableParEditing(!parEditingEnabled))}>
                {/* Rename */}
                <ClipboardDocumentListIcon className="mr-2 h-4 w-4" />
                {parEditingEnabled ? "Disable" : "Enable"} PAR Editing
              </DropdownMenuItem>
              <DropdownMenuSeparator />
            </>
          )}
          <DropdownMenuGroup>
            <DropdownMenuItem onClick={renameOrderGuide}>
              {/* Rename */}
              <Pencil className="mr-2 h-4 w-4" />
              <span>Rename</span>
            </DropdownMenuItem>

            <DropdownMenuItem onClick={() => setShowReorderCategoriesDialog(true)}>
              {/* Reorder Categories */}
              <TagIcon className="mr-2 h-4 w-4" />
              <span>Edit Categories</span>
            </DropdownMenuItem>

            <DropdownMenuItem>
              {/* Reorder Categories */}
              {/*<ArrowsUpDownIcon className="mr-2 h-4 w-4" />*/}
              <div className="flex items-center gap-1">
                <Switch
                  id="enable-item-reordering"
                  checked={allowItemReordering}
                  onCheckedChange={(checked) => setAllowItemReordering(checked)}
                  size="xs"
                ></Switch>
                <Label htmlFor="enable-item-reordering">Enable item reordering</Label>
              </div>
            </DropdownMenuItem>
            {/*
              <DropdownMenuSub>
                <DropdownMenuSubTrigger>
                  <TagIcon className="mr-2 h-4 w-4" />
                  <span>Edit Categories</span>
                </DropdownMenuSubTrigger>
                <DropdownMenuPortal>
                  <DropdownMenuSubContent>
                    <DropdownMenuItem>
                       Add Category

                      <PlusIcon className="mr-2 h-4 w-4" />
                      <span>Add Category</span>
                    </DropdownMenuItem>
                  </DropdownMenuSubContent>
                </DropdownMenuPortal>
              </DropdownMenuSub>*/}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          {orderGuide.type === OrderGuideType.Quantity ? (
            <DropdownMenuItem onClick={toggleOrderGuideMode}>
              <ClipboardEdit className="mr-2 h-4 w-4" />
              Switch to PAR
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem onClick={toggleOrderGuideMode}>
              <ClipboardEdit className="mr-2 h-4 w-4" />
              Switch to Quantity
            </DropdownMenuItem>
          )}
          <DropdownMenuItem className="cursor-pointer text-red-500 hover:bg-red-200 focus:bg-red-200 focus:text-red-600" onClick={deleteOrderGuide}>
            {/* Delete */}
            <Trash className="mr-2 h-4 w-4" />
            <span>Delete</span>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
