import { Log } from "@/Log.ts";
import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { AnimatedCheckmark } from "@/components/display/AnimatedCheckmark.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { useAppDispatch } from "@/store/Hooks.ts";
import { useCreateOrderFromOrderGuideMutation } from "@/store/IRN.API.ts";
import { isProblemDetails, toCurrency } from "@/utils.ts";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { ReactNode, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export function SubmitOrderGuideOrderDialog({ children, orderGuideId }: { children: ReactNode; orderGuideId: number }) {
  const [open, setOpen] = useState(false);
  const [updateTrigger, updateResult] = useCreateOrderFromOrderGuideMutation();
  const [errorText, setErrorText] = useState<ReactNode>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateResult.isSuccess) {
      // setOpen(false);
      // dispatch(
      //   showNotification({
      //     message: "Order submitted",
      //     severity: "success",
      //   })
      // );
    } else {
      if (updateResult.error) {
        const err = updateResult.error as FetchBaseQueryError;
        // const problemDetails = err.data as { type: string; title: string; status: number; detail: string };

        Log.error("SubmitOrderGuideOrderDialog", err);
        setErrorText("An error occurred");
      }
    }
  }, [dispatch, updateResult.isSuccess]);

  useEffect(() => {
    if (open) {
      setErrorText(null);
      updateTrigger(orderGuideId)
        .unwrap()
        .catch((err) => {
          console.log(err);
          if (isProblemDetails(err.data)) {
            const problemDetails = err.data as {
              type: string;
              title: string;
              status: number;
              detail: string;
              errors: Record<string, string[]>;
              MinimumOrderQuantity: number;
              ActualOrderQuantity: number;
              MinimumOrderAmount: number;
              ActualOrderAmount: number;
              Distributor: string;
            };
            setErrorText(
              <div className="grid gap-5 text-left text-sm text-foreground">
                <p className="text-center font-bold text-destructive">An error occurred while submitting the order</p>
                <p className="text-center text-sm text-destructive">{problemDetails.detail}</p>

                {problemDetails.MinimumOrderQuantity > 0 && problemDetails.MinimumOrderAmount > 0 && (
                  <p>
                    <span className="font-semibold">{problemDetails.Distributor}</span> sells unique items that you are ordering, and requires a minimum order
                    quantity of {problemDetails.MinimumOrderQuantity} and a minimum order amount of {problemDetails.MinimumOrderAmount}. You have ordered{" "}
                    {problemDetails.ActualOrderQuantity} items for a total of {toCurrency(problemDetails.ActualOrderAmount)}.
                  </p>
                )}

                {problemDetails.MinimumOrderQuantity == 0 && problemDetails.MinimumOrderAmount > 0 && (
                  <p>
                    <span className="font-semibold">{problemDetails.Distributor}</span> sells unique items that you are ordering, and requires a minimum order
                    amount of {toCurrency(problemDetails.MinimumOrderAmount)}. You have ordered {problemDetails.ActualOrderQuantity} items for a total of{" "}
                    {toCurrency(problemDetails.ActualOrderAmount)}.
                  </p>
                )}

                {problemDetails.MinimumOrderQuantity > 0 && problemDetails.MinimumOrderAmount == 0 && (
                  <p>
                    <span className="font-semibold">{problemDetails.Distributor}</span> sells unique items that you are ordering, and requires a minimum order
                    quantity of {problemDetails.MinimumOrderQuantity}. You have ordered {problemDetails.ActualOrderQuantity} items for a total of{" "}
                    {toCurrency(problemDetails.ActualOrderAmount)}.
                  </p>
                )}

                <p>Please adjust your order to meet the minimum requirements, or contact your sales representative for more information.</p>
              </div>,
            );
          } else {
            setErrorText("An error occurred");
          }
        });
    } else {
      if (updateResult.isSuccess) {
        // navigate(`/app/orders/${updateResult.data?.id}`);
      }
    }
  }, [open, orderGuideId, updateTrigger]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle className="text-center">Submitting order</DialogTitle>
          {/*<DialogDescription>Order guides are a way to organize your orders. You can create as many as you like.</DialogDescription>*/}
        </DialogHeader>
        <div className="flex flex-col gap-5 pt-5 text-center">
          {updateResult.isLoading && (
            <>
              <div className="h-32 place-self-center">
                <LoadingSpinner color="brand" size="6xl" />
              </div>
              <div>
                <p className="">Please wait while we create your optimal order.</p>
                <p className="mt-4 text-xs text-gray-500">
                  If you leave this page during the order creation, you will not lose your spot. Please check back on the Dashboard to confirm the order!
                </p>
              </div>
            </>
          )}

          {updateResult.isSuccess && (
            <>
              <div className="place-self-center">
                <div className="scale-150">
                  <AnimatedCheckmark />
                </div>
              </div>

              <div>
                <p className="text-lg">Your order has been created!</p>

                <p className="mt-4">
                  A payment has <span className="font-semibold text-brand-500">not</span> yet been taken. You will be forwarded to the order review page where
                  you can review the order breakdown, and submit the order for processing.
                </p>
                <p>
                  <Link to={"/help/faq"} className="text-sm text-brand-500 hover:underline">
                    Learn more about the order process
                  </Link>
                </p>
              </div>

              <div className="w-1/2 place-self-center">
                <Button onClick={() => navigate(`/app/orders/${updateResult.data?.orderDetails?.id}`)} className="mt-4 w-full" color="brand">
                  View Order
                </Button>
              </div>
            </>
          )}

          {errorText && (
            <div className="mt-2">
              <p className="text-sm text-red-600">{errorText}</p>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
