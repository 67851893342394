import { OnboardUserRequest } from "@/store/IRN.API.ts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

enum OnboardingSteps {
  WELCOME = 0,
  PERSONAL_INFO = 1,
  BUSINESS_INFO = 2,
  FINISHED = 3,
}

export interface OnboardingSliceState {
  isOnboarding: boolean;

  currentStep: OnboardingSteps;

  onboardingData?: OnboardUserRequest;
}

export const initialState: OnboardingSliceState = {
  isOnboarding: false,
  currentStep: OnboardingSteps.WELCOME,
};

export const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setIsOnboarding: (state, action: PayloadAction<boolean>) => {
      state.isOnboarding = action.payload;
    },

    setCurrentStep: (state, action: PayloadAction<OnboardingSteps>) => {
      state.currentStep = action.payload;
    },

    nextStep: (state) => {
      state.currentStep += 1;
    },

    previousStep: (state) => {
      state.currentStep -= 1;
    },

    patchOnboardingData: (state, action: PayloadAction<OnboardUserRequest>) => {
      state.onboardingData = {
        ...state.onboardingData,
        ...action.payload,
      };
    },
  },
});

export const { setIsOnboarding, setCurrentStep, nextStep, previousStep, patchOnboardingData } = onboardingSlice.actions;

export const selectIsOnboarding = (state: { onboarding: OnboardingSliceState }) => state.onboarding.isOnboarding;

export const selectCurrentStep = (state: { onboarding: OnboardingSliceState }) => state.onboarding.currentStep;

export const selectOnboardingData = (state: { onboarding: OnboardingSliceState }) => state.onboarding.onboardingData;

export default onboardingSlice.reducer;
