import { Button } from "@/components/ui/button.tsx";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { useLazyGetBusinessStatsQuery } from "@/store/IRN.API.ts";
import { useEffect } from "react";
import Joyride, { ACTIONS, CallBackProps, LIFECYCLE, STATUS } from "react-joyride";
import { useNavigate } from "react-router-dom";
import { RecentOrders } from "../../components/dashboard/RecentOrders.tsx";
import { OrderGuideSelectionWidget } from "../../components/order-guide-selection-widget/OrderGuideSelectionWidget.tsx";

const steps = [
  {
    target: ".order-guide-selection-widget",
    content: "Select an order guide to view or upload a new one. Order guides help you manage your orders, inventory, and costs.",
  },
  {
    target: ".recent-orders",
    content: "View recent orders and their statuses. Click on an order to view more details after it has been placed.",
  },
  // {
  //   target: ".business-stats",
  //   content: "View statistics for your business.",
  // },
];

export function Dashboard() {
  const userInfo = useAppSelector(selectUserInfo);
  const activeBusiness = useAppSelector(selectActiveBusiness)!;
  const navigate = useNavigate();

  const [businessStatsTrigger, businessStats] = useLazyGetBusinessStatsQuery();
  const hasRunTour = localStorage.getItem("dashboardTour") === "true";

  useEffect(() => {
    if (activeBusiness && !businessStats.isLoading && !businessStats.data) {
      businessStatsTrigger(activeBusiness.id);
    }
  }, [activeBusiness, businessStats, businessStatsTrigger]);

  useEffect(() => {
    if (!userInfo?.hasOnboarded) {
      navigate("/app/onboarding");
    }
  }, [navigate, userInfo?.hasOnboarded]);

  // function lastStat(stats: BusinessStatDatumDto[]) {
  //   if (stats.length === 0) {
  //     return 0;
  //   }
  //
  //   return stats[stats.length - 1].value;
  // }

  // function getPercentChange(stats: BusinessStatDatumDto[]) {
  //   if (stats.length < 2) {
  //     return 0;
  //   }
  //
  //   const last = lastStat(stats);
  //   const prev = stats[stats.length - 2].value;
  //
  //   return ((last - prev) / prev) * 100;
  // }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, status, type, lifecycle } = data;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (lifecycle === LIFECYCLE.COMPLETE && [ACTIONS.NEXT, ACTIONS.SKIP].includes(action) && [STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // You need to set our running state to false, so we can restart if we click start again.
      console.log("Tour finished", data); //eslint-disable-line no-console
      localStorage.setItem("dashboardTour", "true");
    }

    console.groupCollapsed(type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  return (
    <>
      <Joyride
        run={!hasRunTour}
        steps={steps}
        continuous={true}
        showProgress={true}
        showSkipButton={true}
        styles={{
          buttonNext: {
            backgroundColor: "#ed1d23",
          },
        }}
        callback={handleJoyrideCallback}
      />
      {userInfo?.hasOnboarded ? (
        <>
          {/*<RoleGuard roles={[ApplicationRole.Administrator, ApplicationRole.Developer]}>*/}
          {/*  <div className="mb-12 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-4">*/}
          {/*    {businessStats.data?.map(*/}
          {/*      (item) =>*/}
          {/*        item.stats.length > 0 && (*/}
          {/*          <Card key={item.name}>*/}
          {/*            <p className="flex items-start justify-between">*/}
          {/*              <span className="text-tremor-metric font-semibold text-tremor-content-strong"> {lastStat(item.stats)} </span>*/}
          {/*              <span*/}
          {/*                className={classNames(getPercentChange(item.stats) >= 0 ? "text-emerald-700" : "text-red-700", "text-tremor-default font-semibold")}*/}
          {/*              >*/}
          {/*                {getPercentChange(item.stats).toFixed(1)}%*/}
          {/*              </span>*/}
          {/*            </p>*/}
          {/*            <p className="mt-1 text-tremor-default text-tremor-content"> {item.name} </p>*/}
          {/*            <SparkAreaChart*/}
          {/*              data={item.stats.map((d) => {*/}
          {/*                return { key: d.key, value: d.value };*/}
          {/*              })}*/}
          {/*              index="key"*/}
          {/*              categories={["value"]}*/}
          {/*              colors={["brand-300"]}*/}
          {/*              className="mt-5 w-full"*/}
          {/*            />*/}
          {/*          </Card>*/}
          {/*        ),*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*  /!*<div className="mb-12 grid grid-cols-3 gap-12">*!/*/}
          {/*  /!*  <OrderHistoryChart />*!/*/}
          {/*  /!*</div>*!/*/}
          {/*</RoleGuard>*/}
          <div className="grid md:grid-cols-3 gap-6">
            <OrderGuideSelectionWidget />
            <div className="hidden md:block"></div>
            <RecentOrders />
          </div>
        </>
      ) : (
        <div className="w-full  text-center">
          <h2 className="text-2xl font-bold">
            Welcome to <span className="text-brand">IRN</span>!
          </h2>
          <p>It looks like you don&apos;t have any businesses set up yet.</p>
          <p>You can add business information from your profile.</p>
          <p>Please click the button below to get started.</p>
          <Button className="mt-5" onClick={() => navigate("/app/user/profile")}>
            Add a business
          </Button>

          {/*<p className="pt-10 text-xs text-brand-600">TODO: Change this to a proper onboarding flow</p>*/}
        </div>
      )}

      {/*<p>Services</p>*/}
      {/*<p>Recent orders</p>*/}
      {/*<p>Suggestions for savings</p>*/}
      {/*<p>Order guides</p>*/}
    </>
  );
}
