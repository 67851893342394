import { CreateBusinessInfoDialog } from "@/components/dialogs/create-business-info/CreateBusinessInfoDialog.tsx";
import { UpdateBusinessInfoDialog } from "@/components/dialogs/update-business-info/UpdateBusinessInfoDialog.tsx";
import { UpdateContactInfoDialog } from "@/components/dialogs/update-contact-info/UpdateContactInfoDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx";
import { Skeleton } from "@/components/ui/skeleton.tsx";
import { cn } from "@/lib/utils.ts";
import { selectUser, selectUserInfo } from "@/store/Auth.Slice.ts";
import { selectBusinesses } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { UserCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";

const secondaryNavigation = [
  { name: "General", href: "#", icon: UserCircleIcon, current: true },
  // { name: "Security", href: "#", icon: FingerPrintIcon, current: false, comingSoon: true },
  // { name: "Notifications", href: "#", icon: BellIcon, current: false },
  // { name: "Plan", href: "#", icon: CubeIcon, current: false },
  // { name: "Billing", href: "#", icon: CreditCardIcon, current: false },
  // { name: "Team members", href: "#", icon: UsersIcon, current: false, comingSoon: true },
];

export function Profile() {
  const user = useAppSelector(selectUser);
  const userInfo = useAppSelector(selectUserInfo);

  const businesses = useAppSelector(selectBusinesses);

  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-16 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 lg:block lg:w-64 lg:flex-none lg:border-0">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul role="list" className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col">
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <Link
                    to={item.href}
                    className={cn(
                      item.current ? "bg-gray-50 text-red-600" : "text-gray-700 hover:bg-gray-50 hover:text-red-600",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm font-semibold leading-6",
                      // item.comingSoon && "cursor-not-allowed text-gray-400 hover:bg-transparent hover:text-gray-400"
                    )}
                  >
                    <item.icon
                      className={cn(item.current ? "text-red-600" : "text-gray-400 group-hover:text-red-600", "h-6 w-6 shrink-0")}
                      aria-hidden="true"
                    />
                    {item.name}

                    {/*{item.comingSoon && (*/}
                    {/*  <span className="ml-auto inline-block rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-800">Coming soon</span>*/}
                    {/*)}*/}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 sm:px-6 lg:flex-auto lg:px-0">
          <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <Card>
              <CardHeader>
                <CardTitle className="flex w-full justify-between">
                  Contact
                  <UpdateContactInfoDialog>
                    <Button variant="link">Update</Button>
                  </UpdateContactInfoDialog>
                </CardTitle>
                <CardDescription>How we reach you personally</CardDescription>
              </CardHeader>

              <CardContent>
                <dl className="space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="mt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Full name</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{user?.name ?? userInfo?.firstName + " " + userInfo?.lastName}</div>
                    </dd>
                  </div>
                  <div className="sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email address</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{userInfo?.email}</div>
                    </dd>
                  </div>
                  <div className="sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Phone</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{userInfo?.phoneNumber}</div>
                    </dd>
                  </div>
                </dl>
              </CardContent>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle className="flex w-full justify-between">
                  Businesses
                  <CreateBusinessInfoDialog>
                    <Button variant="link">
                      <span aria-hidden="true">+</span> Add another business
                    </Button>
                  </CreateBusinessInfoDialog>
                </CardTitle>
                <CardDescription>
                  Businesses you have registered with IRN. You can add as many businesses as you like. You can also update your business information here.
                </CardDescription>
              </CardHeader>

              <CardContent>
                <ul role="list" className="divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  {!businesses && <LoadingBusinesses />}
                  {businesses?.map((business: any) => (
                    <li className="flex justify-between gap-x-6 py-6" key={business.id}>
                      <div className="font-medium text-gray-900">
                        <div>{business.name}</div>
                        <div className="text-xs text-gray-500">
                          {business.address.address1}
                          {business.address.address2 ? ", " + business.address.address2 : ""}, {business.address.city}, {business.address.state}{" "}
                          {business.address.zipCode}
                        </div>
                      </div>
                      <UpdateBusinessInfoDialog businessId={business.id}>
                        <Button variant="link">Update</Button>
                      </UpdateBusinessInfoDialog>
                      {/*<button*/}
                      {/*  type="button"*/}
                      {/*  className="font-semibold text-red-600 hover:text-red-500"*/}
                      {/*  onClick={() => {*/}
                      {/*    setEditingBusiness({*/}
                      {/*      businessId: business.id,*/}
                      {/*      name: business.name,*/}
                      {/*      careOf: business.address.careOf,*/}
                      {/*      address1: business.address.address1,*/}
                      {/*      address2: business.address.address2,*/}
                      {/*      city: business.address.city,*/}
                      {/*      state: business.address.state,*/}
                      {/*      zipCode: business.address.zipCode,*/}
                      {/*      taxId: business.taxId,*/}
                      {/*    } as UpdateBusinessRequestDto);*/}
                      {/*    setShowNewBusinessForm(true);*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  Update*/}
                      {/*</button>*/}
                    </li>
                  ))}
                </ul>
              </CardContent>
            </Card>
          </div>
        </main>
      </div>
    </>
  );
}

function LoadingBusinesses() {
  const array = [1, 2, 3];
  return (
    <>
      {array.map((i: any) => (
        <li className="flex justify-between gap-x-6 py-6" key={i}>
          <div className="font-medium text-gray-900">
            <div>
              <Skeleton className="w-[20ch] flex-1" />
            </div>
            <div className="text-xs text-gray-500">
              <Skeleton className="w-[65ch] flex-1" />
            </div>
          </div>
          <button type="button" className="font-semibold text-gray-300" disabled>
            Update
          </button>
        </li>
      ))}
    </>
  );
}
