import { App } from "@/App.tsx";
import { AppContainer } from "@/components/layout/AppContainer.tsx";
import { DevPage } from "@/pages/DevPage.tsx";
import { RebatesPage } from "@/pages/app/Rebates.tsx";
import { OrderGuidePageV2 } from "@/pages/app/order-guide-v2/OrderGuidePage.tsx";
import { OrderGuidePage } from "@/pages/app/order-guide/OrderGuidePage.tsx";
import { OrderGuideNewUploadWizardPage, OrderGuideUploadWizardPage } from "@/pages/app/order-guide/upload/wizard/OrderGuideUploadWizardPage.tsx";
import { ReviewAndMatchItemsStep } from "@/pages/app/order-guide/upload/wizard/ReviewAndMatchItemsStep.tsx";
import { OrderCheckoutPage } from "@/pages/app/orders/Checkout.tsx";
import { OrderReceiptPage } from "@/pages/app/orders/OrderReceiptPage.tsx";
import { ProductInfoPage } from "@/pages/app/products/ProductInfoPage.tsx";
import { CompanyPage } from "@/pages/public/Company.tsx";
import { HomeV3 } from "@/pages/public/HomeV3.tsx";
import { PricingPage } from "@/pages/public/PricingPage.tsx";
import { PrivacyPolicy } from "@/pages/public/PrivacyPolicy.tsx";
import { ReturnPolicy } from "@/pages/public/ReturnPolicy.tsx";
import { TermsOfUse } from "@/pages/public/TermsOfUse.tsx";
import { Route } from "react-router-dom";
import { Dashboard } from "./pages/app/Dashboard.tsx";
import { Onboarding } from "./pages/app/Onboarding.tsx";
import { GuideIndexPage } from "./pages/app/order-guide/GuideIndexPage.tsx";
import { CreateOrderPage } from "./pages/app/orders/CreateOrderPage.tsx";
import { OrderIndexPage } from "./pages/app/orders/OrderIndexPage.tsx";
import { OrderPage } from "./pages/app/orders/OrderPage.tsx";
import { ProductsPage } from "./pages/app/products/ProductsPage.tsx";
import { Profile } from "./pages/app/user/Profile.tsx";
import { ErrorPage } from "./pages/public/ErrorPage.tsx";
import { HomeV2 } from "./pages/public/HomeV2.tsx";
import { Login, Logout } from "./pages/public/Login.tsx";
import { NotFoundPage } from "./pages/public/NotFoundPage.tsx";

export function ApplicationRoutes() {
  return (
    <Route path="/" element={<App />} errorElement={<ErrorPage />}>
      <Route index element={<HomeV3 />} errorElement={<ErrorPage />} />
      <Route path="home2" element={<HomeV2 />} errorElement={<ErrorPage />} />
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
      <Route path="terms-of-use" element={<TermsOfUse />} />
      <Route path="return-policy" element={<ReturnPolicy />} />
      <Route path="pricing" element={<PricingPage />} />
      <Route path="about" element={<CompanyPage />} />

      <Route path="dev" element={<DevPage />} />

      <Route path="/app/onboarding" element={<Onboarding />} />
      <Route path="/app" element={<AppContainer />} errorElement={<ErrorPage />}>
        <Route path="dashboard" element={<Dashboard />} errorElement={<ErrorPage />} />

        <Route path="rebates" element={<RebatesPage />} />

        <Route path="user" errorElement={<ErrorPage />}>
          <Route path="profile" element={<Profile />} />

          <Route path="*" element={<NotFoundPage />} />
        </Route>

        <Route path="order-guide" element={<GuideIndexPage />} />
        {/*<Route path="order-guide/:orderGuideId" element={<OrderGuidePage />} />*/}
        <Route path="order-guide/old/:orderGuideId" element={<OrderGuidePage />} />
        <Route path="order-guide/:orderGuideId" element={<OrderGuidePageV2 />} />

        <Route path="order-guide/:orderGuideId/order" element={<CreateOrderPage />} />
        <Route path="order-guide/upload" element={<OrderGuideUploadWizardPage />} />
        <Route path="order-guide/upload/new" element={<OrderGuideNewUploadWizardPage />} />
        <Route path="order-guide/upload/:uploadId" element={<ReviewAndMatchItemsStep />} />

        <Route path="orders" element={<OrderIndexPage />} />
        <Route path="orders/:orderId" element={<OrderPage />} />
        <Route path="orders/:orderId/checkout" element={<OrderCheckoutPage />} />
        <Route path="orders/:orderId/receipt" element={<OrderReceiptPage />} />

        <Route path="products/:productId" element={<ProductInfoPage />} />
        <Route path="products" element={<ProductsPage />} />

        <Route path="*" element={<NotFoundPage />} />

        <Route path="auth/callback" element={<></>} />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />

      <Route path="*" element={<NotFoundPage />} />
    </Route>
  );
}
