import { UploadRecord } from "@/store/order-guide-upload/wizard/OrderGuideUploadWizard.slice";
import * as XLSX from "xlsx";
import { OrderGuideUploadColumnType } from "@/store/IRN.API.ts";

export interface SupportedDistributorInfo {
  name: string;
  logo?: string;
  value: string;
}

export const SupportedDistributors: SupportedDistributorInfo[] = [
  {
    name: "Kast Foodservice Distributor",
    value: "kast",
    logo: "https://static.wixstatic.com/media/f75fc4_a2656c874e114f499de86220307e1e46~mv2.png/v1/fill/w_294,h_81,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/1-2020%20KAST%20DISTRIBUTORS%20LOGO%20transparen.png",
  },
  // {
  //   name: "Maximum Quality Foods",
  //   value: "mqf",
  //   logo: "https://maximumqualityfoods.com/wp-content/uploads/2024/02/cropped-MQF-Logo-2023-TRANS-Blue-e1708451752120.png",
  // },
  {
    name: "Sysco",
    value: "sysco",
    logo: "https://www.sysco.com/_next/image?url=https%3A%2F%2Fimages.contentstack.io%2Fv3%2Fassets%2Fbltfe3373143ecd3517%2Fblt3396de2cd04bd6c4%2F64ec4404b0b32940a877f96b%2FSysco-Logo-Color1.png&w=750&q=75",
  },
  {
    name: "US Foods",
    value: "usfoods",
    logo: "https://www.usfoods.com/content/dam/dce/images/general/new_usf_logo_0914.png",
  },
  // {
  //   name: "Performance Food Group",
  //   value: "pfg",
  //   logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Performance_Food_Group_logo.svg/413px-Performance_Food_Group_logo.svg.png",
  // },
  {
    name: "Other",
    value: "other",
  },
];

interface OrderGuideUploadWizardDistributorPreset {
  skipFirstRow?: boolean;
  extractUpcMpnFromName?: boolean;
  columns: Record<OrderGuideUploadColumnType, string | null>;
  transformRow?: (row: UploadRecord) => UploadRecord;
  transformColumns?: (columns: string[]) => string[];

  hideColumns?: string[];

  postTransformDetails?: () => React.ReactNode;

  detect?: (sheet: XLSX.WorkSheet) => boolean;
}

const defaultColumns = {
  [OrderGuideUploadColumnType.DistributorSku]: null,
  [OrderGuideUploadColumnType.Name]: null,
  [OrderGuideUploadColumnType.Brand]: null,
  [OrderGuideUploadColumnType.Category]: null,
  [OrderGuideUploadColumnType.Price]: null,
  [OrderGuideUploadColumnType.UnitsPerCase]: null,
  [OrderGuideUploadColumnType.PricePerUnit]: null,
  [OrderGuideUploadColumnType.Size]: null,
  [OrderGuideUploadColumnType.UnitSize]: null,
  [OrderGuideUploadColumnType.Description]: null,
  [OrderGuideUploadColumnType.Unit]: null,
  [OrderGuideUploadColumnType.CategoryId]: null,
  [OrderGuideUploadColumnType.Manufacturer]: null,
  [OrderGuideUploadColumnType.Upc]: null,
  [OrderGuideUploadColumnType.Thumbnail]: null,
  [OrderGuideUploadColumnType.Images]: null,
  [OrderGuideUploadColumnType.Unknown]: null,
  [OrderGuideUploadColumnType.IsCatchWeight]: null,
};

export function findDistributorPresetFromWorksheet(sheet: XLSX.WorkSheet): { distributorKey: string; preset: OrderGuideUploadWizardDistributorPreset } | null {
  for (const [key, preset] of Object.entries(OrderGuideUploadWizardDistributorPresets)) {
    if (preset.detect && preset.detect(sheet)) {
      // Get the "Name" of the distributor from the supported distributors list
      const distributor = SupportedDistributors.find((d) => d.value === key);

      if (!distributor) {
        console.error("Distributor not found in the supported distributors list");
        return null;
      }

      // return { distributorKey: distributor?.name, preset };
      return { distributorKey: key, preset };
    }
  }

  return null;
}

export const OrderGuideUploadWizardDistributorPresets: Record<string, OrderGuideUploadWizardDistributorPreset> = {
  sysco: {
    skipFirstRow: true,
    hideColumns: ["F", "Min Split", "Split $", "Splittable", "Stock", "Item Status"],
    columns: {
      ...defaultColumns,
      [OrderGuideUploadColumnType.DistributorSku]: "SUPC",
      [OrderGuideUploadColumnType.Name]: "Desc",
      [OrderGuideUploadColumnType.Brand]: "Brand",
      [OrderGuideUploadColumnType.Category]: "Cat",
      [OrderGuideUploadColumnType.Price]: "Case $",
      [OrderGuideUploadColumnType.UnitsPerCase]: "Pack",
      [OrderGuideUploadColumnType.PricePerUnit]: "Price Per Lb",
      [OrderGuideUploadColumnType.UnitSize]: "Unit Size",
    },
    transformColumns: (columns: string[]) => {
      return [...columns, "Unit Size", "Price Per Lb"];
    },
    transformRow: (record: UploadRecord) => {
      const row = { ...record.record };

      // Try to translate Pack/Size into a UnitSize
      const pack = row["Pack"];
      const size = row["Size"];

      if (pack && size) {
        row["Unit Size"] = `${pack}/${size}`;
      }

      // If the item is a catch weight item, calculate the total case price, and update the values in the row
      if (row["Per Lb"] === "Y") {
        // NOTE: Net Wt already includes some overhead for packaging, so we don't need to add any more
        const netWt = parseFloat(row["Net Wt"]);
        const pricePerLb = parseFloat(row["Case $"]);

        console.log("Net Wt", netWt, "Price Per Lb", pricePerLb);
        row["Case $"] = tryDetermineActualCasePriceFromCatchWeight(pricePerLb, parseFloat(row["Pack"]), row["Size"], netWt, row["Unit"]).toFixed(2);
        console.log("Case $", row["Case $"]);
        row["Price Per Lb"] = pricePerLb.toFixed(2);
      }

      return {
        record: row,
        raw: record.raw,
      };
    },
    postTransformDetails: () => {
      return (
        <>
          <p>The following columns have been added to your file:</p>
          <ul className="font-semibold">
            <li>Unit Size</li>
            <li>Price Per Lb</li>
          </ul>
          <p>
            We have also adjusted the <span className="font-semibold">Case $</span> field, to be the actual case price based on the catch weight information.
          </p>
        </>
      );
    },

    detect: (sheet: XLSX.WorkSheet) => {
      // Quickly check for known Sysco fields
      // F, SUPC, Case $, Split $ -> most likely sysco
      // If all of these are present, it's a sysco file
      if (sheet[XLSX.utils.encode_cell({ r: 0, c: 0 })].v !== "H") {
        return false;
      }

      // 2nd row:
      // F, SUPC, Case Qty, Split Qty
      if (sheet[XLSX.utils.encode_cell({ r: 1, c: 0 })].v !== "F") {
        return false;
      }
      if (sheet[XLSX.utils.encode_cell({ r: 1, c: 1 })].v !== "SUPC") {
        return false;
      }
      if (sheet[XLSX.utils.encode_cell({ r: 1, c: 2 })].v !== "Case Qty") {
        return false;
      }
      if (sheet[XLSX.utils.encode_cell({ r: 1, c: 3 })].v !== "Split Qty") {
        return false;
      }

      return true;
    },
  },

  usfoods: {
    skipFirstRow: false,
    hideColumns: ["Line Number", "Group Name", "Storage Description", "Product Status", "Product Type"],
    columns: {
      ...defaultColumns,
      [OrderGuideUploadColumnType.DistributorSku]: "Product Number",
      [OrderGuideUploadColumnType.Name]: "Product Description",
      [OrderGuideUploadColumnType.Brand]: "Product Brand",
      [OrderGuideUploadColumnType.Price]: "Case $",
      [OrderGuideUploadColumnType.UnitsPerCase]: "Product Package Size",
      [OrderGuideUploadColumnType.UnitSize]: "Product Package Size",
      [OrderGuideUploadColumnType.Unit]: "Product UOM",
      [OrderGuideUploadColumnType.Category]: "USF Class Description",
    },

    transformRow: (record: UploadRecord) => {
      const row = { ...record.record };

      // If the item is a catch weight item, calculate the total case price, and update the values in the row

      const packSize = row["Product Package Size"];
      const productPrice = parseFloat(row["Product Price"]);
      const isPerLb = row["Product UOM"] === "LB";

      const result = tryParseProductPackageSizeAndPrice(packSize, productPrice, isPerLb);
      console.log("Result", result);

      if (result.pack) {
        row["Pack"] = result.pack;
      }

      if (result.unitSize) {
        row["Unit Size"] = result.unitSize;
      }

      if (result.unit) {
        row["Unit"] = result.unit;
      }

      if (result.size) {
        row["Size"] = result.size;
      }

      if (result.pricePerLb) {
        row["Price Per Lb"] = result.pricePerLb;
      }

      if (result.casePrice) {
        row["Case $"] = result.casePrice;
      }

      return {
        record: row,
        raw: record.raw,
      };
    },
    transformColumns: (columns: string[]) => {
      return [...columns, "Pack", "Size", "Unit", "Net Wt", "Price Per Lb", "Case $"];
    },
    detect: (sheet: XLSX.WorkSheet) => {
      // Quickly check for known US Foods fields, these are not necessarily in order
      return [
        "Line Number",
        "Group Name",
        "Product Number",
        "Product Status",
        "Product Description",
        "Product Brand",
        "Product Package Size",
        "Customer Product Number",
        "Product Price",
        "Product UOM",
        "Product Type",
        "Product Note",
        "USF Class Description",
        "Storage Description",
      ].every((col, i) => sheet[XLSX.utils.encode_cell({ r: 0, c: i })].v === col);
    },
  },

  kast: {
    skipFirstRow: true,
    columns: {
      ...defaultColumns,
      [OrderGuideUploadColumnType.DistributorSku]: "Item #",
      [OrderGuideUploadColumnType.Name]: "Description",
      [OrderGuideUploadColumnType.Brand]: "Brand",
      [OrderGuideUploadColumnType.UnitSize]: "Pack",
      [OrderGuideUploadColumnType.Price]: "Price",
    },
    transformColumns: (columns: string[]) => {
      return columns;
    },
    transformRow: (record: UploadRecord) => {
      const row = { ...record.record };

      // Try to translate Pack/Size into a UnitSize
      const pack = row["Pack"];
      const size = row["Size"];

      if (pack && size) {
        row["Unit Size"] = `${pack}/${size}`;
      }

      return {
        record: row,
        raw: record.raw,
      };
    },
    detect: (sheet: XLSX.WorkSheet) => {
      // Quickly check for known Kast fields

      return ["Item #", "UM", "Brand", "Pack", "Description", "Price by UM", "Price", "Extended Price"].every(
        (col, i) => sheet[XLSX.utils.encode_cell({ r: 0, c: i })].v === col,
      );
    },
  },
};

// export function tryDetermineActualCasePriceFromCatchWeight(pricePerLb: number, pack: number, size: string, assumedNetWt: number, unit?: string) {
//   // Strip out AV/AVG from the size
//   size = size.replace(/#AVG?$/, "");
//
//   // Replace # with LB. There may or may not be a space before the #. Make sure the final result has a space before the LB, but only one space.
//   size = size.replace(/ ?# ?/, " LB ");
//   // Replace "LBA" with "LB" - this is LB average
//   size = size.replace("LBA", "LB");
//
//   if (size.endsWith("OZ")) {
//     // Convert OZ to LB
//     const oz = parseFloat(size.replace("OZ", ""));
//     size = `${oz / 16} LB`;
//   }
//
//   if (unit === "PCE" || size.endsWith("PC")) {
//     // When we get a "PC", we need to go with NetWt/Pack to get the size
//     size = `${assumedNetWt / pack} LB`;
//   }
//
//   if (unit === "CT" || size.endsWith("CT")) {
//     // Get the count from the size
//     const count = parseFloat(size.replace("CT", ""));
//     size = `${(assumedNetWt / pack) * count} LB`;
//   }
//
//   // Calculate the total
//   const totalSize = parseFloat(size.replace(" LB", ""));
//   const casePrice = pricePerLb * totalSize;
//
//   return casePrice;
// }

export function tryDetermineActualCasePriceFromCatchWeight(pricePerLb: number, pack: number, size: string, assumedNetWt: number, unit: string): number {
  // Helper function to convert weight to pounds if necessary
  const convertToPounds = (weight: number, unit: string): number => {
    if (unit.toUpperCase() === "OZ") {
      return weight / 16; // Convert ounces to pounds
    }
    return weight;
  };

  // Helper function to round weight per piece for '1PC' in 'LB' unit
  const roundWeightPerPiece = (weight: number, unit: string, size: string): number => {
    if (unit === "LB" && (size === "1PC" || size === "PC")) {
      return Math.ceil(weight);
    }
    return weight;
  };

  function roundToNearestMidpointCeiling(num: number): number {
    return Math.ceil(num * 2) / 2;
  }

  // First step first, replace generic LB, #, #AV, #AVG, AVG, # UP, etc with "LB" to simplify the parsing
  size = size
    .replace(/AVG?|UP/g, "")
    .replace("PCE", "PC")
    .replace(/#/g, "LB")
    .trim();

  // If the size is a range (1-5LB, 1-5 LB, etc), take the upper bound
  if (size.includes("-")) {
    size = size.split("-")[1];
  }

  // Extract the size of the unit from the size value. Eg; LB, OZ, PC, CT, etc. If not found, default to LB
  const sizeUnit = size.match(/(LB|OZ|PC|CT)/)?.[0] || "LB";

  // Get just the numeric size value
  const sizeValue = parseFloat(size.replace(/[a-zA-Z]/g, ""));

  // If the sizeUnit is PC
  if (sizeUnit === "PC") {
    // Calculate the average weight per piece
    const avgWeightPerPiece = assumedNetWt / pack / sizeValue;
    // Round the average weight per piece up
    const weight = roundToNearestMidpointCeiling(avgWeightPerPiece);
    // Calculate the price per case
    return parseFloat((pack * weight * pricePerLb).toFixed(3));
  }

  if (sizeUnit === "CT") {
    // Extract the count from the size
    const count = parseFloat(size.replace("CT", ""));
    // Calculate the weight per piece
    const weightPerPiece = assumedNetWt / pack;
    // Calculate the total weight
    const totalWeight = weightPerPiece * count;
    // Calculate the price per case
    return parseFloat((totalWeight * pricePerLb).toFixed(3));
  }

  // Extract numerical values from size
  const nums = size.match(/\d+\.?\d*/g)?.map((num) => parseFloat(num)) || [];

  let weight: number = 0;

  if (["PC", "PCE"].some((suffix) => size.includes(suffix)) && nums.length === 1) {
    // For '1PC' in 'LB' unit, round the average weight per piece up
    const avgWeightPerPiece = assumedNetWt / pack;
    weight = roundWeightPerPiece(avgWeightPerPiece, unit, size);
  } else if (size.includes("AVG") || size.includes("AV") || size.includes("#")) {
    weight = nums[0];
  } else if (size.includes("-")) {
    weight = nums[nums.length - 1];
  } else if (size.includes("OZ")) {
    weight = convertToPounds(nums[0], "OZ");
  } else if (size.includes("UP")) {
    weight = nums[0];
  } else if (nums.length === 1) {
    weight = nums[0];
  } else {
    throw new Error("Unrecognized size format");
  }

  const weightInPounds = convertToPounds(weight, unit.includes("OZ") ? "OZ" : "LB");
  const price = parseFloat((pack * weightInPounds * pricePerLb).toFixed(3));
  return price;
}

function tryParseProductPackageSizeAndPrice(packSize: string, productPrice: number, isPerLb: boolean) {
  const result: any = {
    packSize,
    productPrice,
    isPerLb,
  };

  // Most pack sizes are "2/10 LB", or "4x5 LB", or "2/10#" or "4 x 5#" etc
  // Split by / or x
  const parts = packSize.split(/[/x]/);
  if (parts.length === 2) {
    const pack = parseFloat(parts[0]);

    const maybeSize = parts[1];

    result.pack = pack;
    result.packUnitSize = maybeSize;

    // Now determine the size suffix here (LB, OZ, CT, etc)
    // If it's not a number, it's the unit
    const unitSize = parts[1].replace(/[0-9. ]/g, "").trim();
    const unit = parts[1].replace(/[^0-9. ]+$/, "").trim();
    result.unitSize = unitSize;
    result.unit = unit;

    // unitSize is now "10" or "5" or "10.1" or "1.00"
    const size = parseFloat(unit);
    result.size = size;

    result.packSizeTotal = pack * size;

    // If the unit is LB, we can calculate the price per lb
    if (isPerLb) {
      // Calculate the total case price
      const casePrice = productPrice * result.packSizeTotal;
      result.casePrice = casePrice;
      result.pricePerLb = productPrice;
    } else {
      // Calculate the price per unit
      const pricePerUnit = productPrice / result.packSizeTotal;
      result.pricePerUnit = pricePerUnit;
      result.casePrice = productPrice;
    }
  }

  return result;
}

// export function isSyscoOrderGuideUpload(state: OrderGuideUploadState): boolean {
//   // Quickly check for known Sysco fields
//   // F, SUPC, Case $, Split $ -> most likely sysco
//   // If all of these are present, it's a sysco file

//   if (!state.columns) {
//     return false;
//   }
//   // If the 2nd row has the following column values, it's a sysco file
//   const secondRowValues = Object.values(state.records![0].record);

//   return secondRowValues.includes("F") && secondRowValues.includes("SUPC") && secondRowValues.includes("Case $") && secondRowValues.includes("Split $");
// }

// export function modifyStateForSyscoOrderGuideUpload(state: FileUploadState): void {
//   // If it's a sysco file, we need to remove the first row
//   // and remove the first column
//   // state.skipFirstRow = true;
//   // state.columns = Object.values(state.records![0].record);
//   // state.records = state.records!.slice(1);
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   state.columnTypes = {
//     [OrderGuideUploadColumnType.Sku]: "SUPC",
//     [OrderGuideUploadColumnType.Name]: "Desc",
//     [OrderGuideUploadColumnType.Brand]: "Brand",
//     [OrderGuideUploadColumnType.UnitSize]: "Size",
//     [OrderGuideUploadColumnType.Price]: "Case $",
//   };
// }

// export function isKastOrderGuideUpload(sheet: XLSX.WorkSheet): boolean {
//   // Quickly check for known Kast fields
//
//   return ["Item #", "UM", "Brand", "Pack", "Description", "Price by UM", "Price", "Extended Price"].every(
//     (col, i) => sheet[XLSX.utils.encode_cell({ r: 0, c: i })].v === col,
//   );
// }
//
// export function modifyStateForKastOrderGuideUpload(state: FileUploadState): void {
//   // If it's a kast file, we need to remove the first row
//   // and remove the first column
//   // state.skipFirstRow = true;
//   // state.columns = Object.values(state.records![0].record);
//   // state.records = state.records!.slice(1);
//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   state.columnTypes = {
//     [OrderGuideUploadColumnType.Sku]: "Item #",
//     [OrderGuideUploadColumnType.Name]: "Description",
//     [OrderGuideUploadColumnType.Brand]: "Brand",
//     [OrderGuideUploadColumnType.UnitSize]: "Pack",
//     [OrderGuideUploadColumnType.Price]: "Price",
//   };
// }
