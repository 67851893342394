import { msalPca } from "@/Security.ts";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import queryString from "query-string";

const baseQuery = fetchBaseQuery({
  baseUrl: "/",
  prepareHeaders: async (headers, api) => {
    await msalPca.initialize();
    if (!msalPca.getActiveAccount()) {
      console.log("Setting active account to", JSON.stringify(msalPca.getAllAccounts()[0], null, 2));
      msalPca.setActiveAccount(msalPca.getAllAccounts()[0]);
    }

    if (!msalPca.getActiveAccount()) {
      console.log("No active account found");
      return headers;
    }

    const accessToken = await msalPca.acquireTokenSilent({
      scopes: ["https://irnworks.onmicrosoft.com/api/user_access"],
      account: msalPca.getAllAccounts()[0],
    });

    // const accessToken = (api.getState() as RootState).auth.user?.idToken;
    // console.log("accessToken", accessToken);

    console.log(api);
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken.accessToken}`);
    }

    // if (api.arg && !(api.arg instanceof String) && "method" in (api.arg as any)) {
    //   if (!headers.has("Content-Type") && (api.arg as FetchArgs)?.method !== "GET") {
    //     headers.set("Content-Type", "application/json");
    //   }
    // }

    return headers;
  },

  paramsSerializer: (params) => {
    // query-string automatically formats the params into a format that .NET expects
    // Eg; array params are formatted as `key=value1&key=value2` instead of `key=value1,value2` which doesn't scale for params with commas
    return queryString.stringify(params);
  },
});

// initialize an empty api service that we'll inject endpoints into later as needed
export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: baseQuery,
  endpoints: () => ({}),
  tagTypes: [],
});
