import { BusinessDto } from "@/store/IRN.API.ts";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface BusinessState {
  activeBusiness?: BusinessDto;
  businesses: BusinessDto[];
}

export const initialState: BusinessState = {
  activeBusiness: undefined,
  businesses: [],
};

export const businessSlice = createSlice({
  name: "business",
  initialState,
  reducers: {
    setActiveBusiness: (state, action: PayloadAction<BusinessDto | undefined>) => {
      state.activeBusiness = action.payload;

      // Save the active business to local storage
      if (action.payload) {
        localStorage.setItem("activeBusinessId", action.payload.id!.toString());
      } else {
        localStorage.removeItem("activeBusinessId");
      }
    },
    setBusinesses: (state, action: PayloadAction<BusinessDto[]>) => {
      state.businesses = action.payload;
    },
  },
});

export const { setActiveBusiness, setBusinesses } = businessSlice.actions;

export default businessSlice.reducer;

export const selectActiveBusiness = (state: { business: BusinessState }) => state.business.activeBusiness;
export const selectBusinesses = (state: { business: BusinessState }) => state.business.businesses;
