import { AddressForm } from "@/components/forms/AddressForm.tsx";
import { IrnInput, IrnSelect } from "@/components/forms/IrnInput.tsx";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { PhoneInput } from "@/components/ui/phone-input.tsx";
import { BusinessRegistrationType } from "@/store/IRN.API.ts";
import { clsx } from "clsx";

export function BusinessForm({ control, namePrefix, dialog, requireFields }: { control: any; namePrefix?: string; dialog?: boolean; requireFields?: boolean }) {
  if (namePrefix) {
    namePrefix = namePrefix + ".";
  }

  return (
    <div className={clsx("grid grid-cols-1 gap-5", !dialog && "sm:grid-cols-2")}>
      <FormField
        control={control}
        name={`${namePrefix}operatingName`}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Operating (DBA) Name</FormLabel>
            <FormControl>
              <Input placeholder="" type="text" {...field} />
            </FormControl>
            <FormDescription>The operating business name that you show on your customers invoices or receipts.</FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />

      {/*<IrnInput*/}
      {/*  control={control}*/}
      {/*  name={`${namePrefix}operatingName`}*/}
      {/*  label="Operating (DBA) Name"*/}
      {/*  description="The operating business name that is shown on your customer's receipts and invoices"*/}
      {/*/>*/}
      {/*<IrnInput control={control} name={`${namePrefix}name`} label="Legal Name" description="Enter the name that your business is registered under" />*/}

      <div className="grid grid-cols-2 gap-2">
        <div>
          <FormField
            control={control}
            name={`${namePrefix}name`}
            render={({ field }) => (
              <FormItem>
                <FormLabel>Legal Name</FormLabel>
                <FormControl>
                  <Input placeholder="" type="" {...field} />
                </FormControl>
                <FormDescription>The name used on your tax & legal documents.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <IrnInput control={control} name={`${namePrefix}taxId`} label="Tax ID" description="The tax ID of the business." />
      </div>

      <div className="sm:col-span-full">
        <div>
          <IrnSelect
            control={control}
            name={`${namePrefix}registrationType`}
            label="Registration Type"
            required={requireFields}
            description="The registration type of the business as it appears on your tax forms (W9)"
            options={[
              { label: "Sole Proprietorship", value: BusinessRegistrationType.SoleProprietorship },
              { label: "Registered Partnership", value: BusinessRegistrationType.Partnership },
              { label: "Incorporated", value: BusinessRegistrationType.Incorporated },
              { label: "Non-Profit", value: BusinessRegistrationType.NonProfit },
              { label: "LLC (D - Disregarded Entity)", value: BusinessRegistrationType.LlcDisregardedEntity },
              { label: "LLC (C - Corporation)", value: BusinessRegistrationType.LlccCorporation },
              { label: "LLC (P - Partnership)", value: BusinessRegistrationType.LlcPartnership },
              { label: "Sub S Corporation", value: BusinessRegistrationType.SubSCorporation },
            ]}
          />
        </div>
      </div>
      <IrnInput control={control} name={`${namePrefix}email`} label="Email" />
      {/*<IrnInput control={control} name={`${namePrefix}phoneNumber`} label="Phone Number" />*/}
      <FormField
        control={control}
        name="phoneNumber"
        render={({ field }) => (
          <FormItem className="flex flex-col items-start">
            <FormLabel>Phone number</FormLabel>
            <FormControl className="w-full">
              <PhoneInput placeholder="" {...field} defaultCountry="US" />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="sm:col-span-full">
        <AddressForm control={control} namePrefix={`${namePrefix}address`} />
      </div>
    </div>
  );
}
