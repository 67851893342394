import { FullPageStepper } from "@/components/FullPageStepper.tsx";
import { JsonDataDump } from "@/components/JsonDataDump.tsx";
import { RoleGuard } from "@/components/authentication/RoleGuard.tsx";
import { AddressForm } from "@/components/forms/AddressForm.tsx";
import { IrnInput, IrnSelect } from "@/components/forms/IrnInput.tsx";
import { addressFormValidationSchema } from "@/components/forms/fields/AddressFormValidation.tsx";
import { AppContainerNoSidebar } from "@/components/layout/AppContainer.tsx";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form.tsx";
import { Input } from "@/components/ui/input.tsx";
import { PhoneInput } from "@/components/ui/phone-input.tsx";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { ApplicationRole, BusinessRegistrationType, OnboardUserRequest, useOnboardUserMutation } from "@/store/IRN.API.ts";
import { selectIsOnboarding } from "@/store/Onboarding.slice.ts";
import { useAccount } from "@azure/msal-react";
import { DevTool } from "@hookform/devtools";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { ObjectSchema } from "yup";

const validationSchema: ObjectSchema<OnboardUserRequest> = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .test("is-valid-phone-number", "Please enter a valid phone number", (value) => !value || isValidPhoneNumber(value)),
  address: addressFormValidationSchema,
  businessName: yup.string().required("Business name is required"),
  businessTaxId: yup.string().required("Business tax ID is required"),
  businessEmail: yup.string().required("Business email is required").email(),
  businessPhoneNumber: yup
    .string()
    .required()
    .test("is-valid-phone-number", "Please enter a valid phone number", (value) => !value || isValidPhoneNumber(value)),
  businessOperatingName: yup.string().required("Operating name is required"),
  businessAddress: addressFormValidationSchema,
  businessRegistrationType: yup.mixed<BusinessRegistrationType>().required("Registration type is required").oneOf(Object.values(BusinessRegistrationType)),
});

export function Onboarding() {
  const accountInfo = useAccount();
  const isOnboarding = useAppSelector(selectIsOnboarding);
  // const currentStep = useAppSelector(selectCurrentStep);
  const userInfo = useAppSelector(selectUserInfo);

  const form = useForm<OnboardUserRequest>({
    mode: "onBlur",
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstName: accountInfo?.name?.split(" ")[0] ?? "",
      lastName: accountInfo?.name?.split(" ")[1] ?? "",
      phoneNumber: "",
      address: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        careOf: "",
      },
      businessName: "",
      businessTaxId: "",
      businessPhoneNumber: "",
      businessRegistrationType: undefined,
      businessOperatingName: "",
      businessEmail: "",
      businessAddress: {
        address1: "",
        address2: "",
        city: "",
        state: "",
        zipCode: "",
        careOf: "",
      },
    },
  });
  const navigate = useNavigate();
  const [onboardUserMutationTrigger /*, onboardUserMutation*/] = useOnboardUserMutation();

  useEffect(() => {
    if (!isOnboarding && userInfo?.hasOnboarded) {
      navigate("/app/dashboard");
    }
  }, [isOnboarding, navigate, userInfo?.hasOnboarded]);

  const onSubmit = (data: OnboardUserRequest) => {
    console.log(data);
    // TODO: Handle form submission
    onboardUserMutationTrigger({
      ...data,
    });
  };

  const isPersonalFormPartValid = () =>
    form.formState.isDirty &&
    form.formState.errors.firstName === undefined &&
    form.formState.errors.lastName === undefined &&
    form.formState.errors.phoneNumber === undefined &&
    form.formState.errors.address === undefined &&
    // Make sure the fields have values
    form.getValues("firstName") !== "" &&
    form.getValues("lastName") !== "" &&
    form.getValues("phoneNumber") !== "" &&
    form.getValues("address.address1") !== "" &&
    form.getValues("address.city") !== "" &&
    form.getValues("address.state") !== "" &&
    form.getValues("address.zipCode") !== "";

  const isBusinessFormPartValid = () => {
    return (
      form.formState.isDirty &&
      form.formState.errors.businessName === undefined &&
      form.formState.errors.businessTaxId === undefined &&
      form.formState.errors.businessPhoneNumber === undefined &&
      form.formState.errors.businessRegistrationType === undefined &&
      form.formState.errors.businessOperatingName === undefined &&
      form.formState.errors.businessEmail === undefined &&
      form.formState.errors.businessAddress === undefined &&
      // Make sure the fields have values
      form.getValues("businessName") !== "" &&
      form.getValues("businessTaxId") !== "" &&
      form.getValues("businessPhoneNumber") !== "" &&
      form.getValues("businessRegistrationType") !== undefined &&
      form.getValues("businessOperatingName") !== "" &&
      form.getValues("businessEmail") !== "" &&
      form.getValues("businessAddress.address1") !== "" &&
      form.getValues("businessAddress.city") !== "" &&
      form.getValues("businessAddress.state") !== "" &&
      form.getValues("businessAddress.zipCode") !== ""
    );
  };

  return (
    <AppContainerNoSidebar>
      {/*<div className="container px-5 sm:px-0">*/}
      {/*<h1 className="scroll-m-20 border-b pb-2 text-3xl font-semibold tracking-tight transition-colors first:mt-0">*/}
      {/*  Welcome to <span className="text-brand">IRN</span>!*/}
      {/*</h1>*/}
      {/*<p className="onboarding-welcome-subheader">Please fill out the information below to get started.</p>*/}
      <DevTool control={form.control} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="h-full">
          <FullPageStepper onComplete={() => console.log("Complete")} onNextPage={() => console.log("Next")} onPrevPage={() => console.log("Prev")}>
            <FullPageStepper.Step
              id="1"
              imageURL="/onboarding_1.webp"
              imageLocation="left"
              imageAlt="Step 1"
              imageSideClassName=""
              canMoveNext={isPersonalFormPartValid}
            >
              <div className="flex gap-2 p-10 flex-col max-w-4xl">
                <h1>Personal Information</h1>
                <div>
                  <p>This information will be used to identify you across the platform, and to contact you if necessary.</p>
                  <p className="text-sm mt-2">
                    Note: Please enter your personal information, <strong>not</strong> your business information. We will ask for that in the next steps.
                  </p>
                </div>
                <FormField
                  control={form.control}
                  name="firstName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>First Name</FormLabel>
                      <FormControl>
                        <Input {...field} autoComplete="given-name" />
                      </FormControl>
                      {/*<FormDescription>This is your public display name.</FormDescription>*/}
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="lastName"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Last Name</FormLabel>
                      <FormControl>
                        <Input {...field} autoComplete="family-name" />
                      </FormControl>
                      {/*<FormDescription>This is your public display name.</FormDescription>*/}
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* phoneNumber */}
                <FormField
                  control={form.control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone Number</FormLabel>
                      <FormControl>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <PhoneInput {...field} defaultCountry="US" placeholder="Enter a phone number" />
                      </FormControl>
                      {/*<FormDescription>This is your public display name.</FormDescription>*/}
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <AddressForm control={form.control} namePrefix="address" />
              </div>
            </FullPageStepper.Step>

            <FullPageStepper.Step
              id="2"
              imageURL="/onboarding_1.webp"
              imageLocation="left"
              imageAlt="Step 2"
              imageSideClassName=""
              canMoveNext={isBusinessFormPartValid}
            >
              <div className="flex gap-2 p-10 flex-col max-w-4xl">
                <h1>Business Information</h1>
                <div className="grid gap-2">
                  <p>This information will be used to send invoices, ship orders, and to identify your business.</p>

                  {/*<p>*/}
                  {/*  You don't have to fill out all the information presented. You are free to use our services until such time as that information is fully*/}
                  {/*  required. At that point, we will ask you to provide the information before you can continue using our services. Required information is*/}
                  {/*  marked with a <span className="text-red-500">*</span>.*/}
                  {/*</p>*/}
                  <p>
                    Have more businesses? No problem! You&apos;ll be able to register more businesses after you complete this onboarding process. For now,
                    let&apos;s keep it simple and get you up and running.
                  </p>

                  {/*<p>*/}
                  {/*  <span className="font-semibold">Why do we require your business ZIP code?</span>*/}
                  {/*  <br />*/}
                  {/*  We need your business address to make sure we're showing the right products for your service area. Some products and services are only*/}
                  {/*  available in certain areas, so we use your ZIP code to make sure we're showing you the right products for your business.*/}
                  {/*</p>*/}
                </div>

                <IrnInput
                  control={form.control}
                  name={"business.operatingName"}
                  label="Operating (DBA) Name"
                  description="The operating business name that is shown on your customer's receipts and invoices"
                />
                <IrnInput control={form.control} name={`business.name`} label="Legal Name" description="The legal name of the business." />
                <IrnInput
                  control={form.control}
                  name={`business.email`}
                  label="Email"
                  description="The email address of the business."
                  type="email"
                  autoComplete="email"
                />

                {/*<IrnInput control={form.control} name={`business.phoneNumber`} label="Phone Number" description="The phone number of the business." />*/}
                <FormField
                  control={form.control}
                  name="businessPhoneNumber"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Phone Number</FormLabel>
                      <FormControl>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <PhoneInput {...field} defaultCountry="US" placeholder="Enter a phone number" />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <AddressForm control={form.control} namePrefix={`business.address`} />

                <div className="mt-2">
                  <IrnSelect
                    control={form.control}
                    name={`business.registrationType`}
                    label="Registration Type"
                    description="The registration type of the business as it appears on your tax forms (W9)"
                    options={[
                      { label: "Sole Proprietorship", value: BusinessRegistrationType.SoleProprietorship },
                      { label: "Registered Partnership", value: BusinessRegistrationType.Partnership },
                      { label: "Incorporated", value: BusinessRegistrationType.Incorporated },
                      { label: "Non-Profit", value: BusinessRegistrationType.NonProfit },
                      { label: "LLC (D - Disregarded Entity)", value: BusinessRegistrationType.LlcDisregardedEntity },
                      { label: "LLC (C - Corporation)", value: BusinessRegistrationType.LlccCorporation },
                      { label: "LLC (P - Partnership)", value: BusinessRegistrationType.LlcPartnership },
                      { label: "Sub S Corporation", value: BusinessRegistrationType.SubSCorporation },
                    ]}
                  />
                </div>

                {/*<IrnInput<OnboardUserRequestDto> control={form.control} name={`business.taxId`} label="Tax ID" description="The tax ID of the business." />*/}
                <FormField
                  control={form.control}
                  name="businessTaxId"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Tax ID</FormLabel>
                      <FormControl>
                        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                        {/* @ts-ignore */}
                        <Input {...field} />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </FullPageStepper.Step>
          </FullPageStepper>

          {/*<div className="mt-10 flex w-full justify-end px-5">*/}
          {/*  <Button type="submit" className="w-full sm:w-max" disabled={onboardUserMutation.isLoading}>*/}
          {/*    {onboardUserMutation.isLoading && <LoadingSpinner className="mr-2" size="sm" />}*/}
          {/*    Continue*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </form>
      </Form>
      <RoleGuard roles={[ApplicationRole.Developer]}>
        <JsonDataDump data={form.formState.errors} />
      </RoleGuard>
      {/*</div>*/}
    </AppContainerNoSidebar>
  );
}
