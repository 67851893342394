import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/lib/utils";

// "default" | "destructive" | "outline" | "secondary" | "ghost" | "link" | "no-bg" | "success";
const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive: "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline: "border border-input bg-background hover:bg-accent hover:text-accent-foreground",
        secondary: "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",

        "outline-success": "border border-emerald-600 bg-transparent shadow-sm hover:bg-emerald-500 hover:text-emerald-50 text-emerald-600",
        "outline-destructive": "border border-destructive bg-background hover:bg-destructive hover:text-destructive-foreground",
        "outline-secondary": "border border-secondary bg-background hover:bg-secondary hover:text-secondary-foreground",
        "no-bg": "hover:text-accent-foreground",

        icon: "text-primary hover:bg-primary/20",
        "icon-gray": "text-gray-300 hover:bg-gray-200",
        success: "bg-emerald-500 text-emerald-50 shadow hover:bg-emerald-600",
      },
      size: {
        default: "h-10 px-4 py-2",
        xs: "h-8 rounded-md px-2 text-xs",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        xl: "h-12 rounded-md px-10",
        icon: "h-10 w-10 [&_svg]:h-7 [&_svg]:w-7",
        "sm-icon": "h-5 w-5",
        "2xl": "h-14 rounded-md px-12",
        "3xl": "h-16 rounded-md px-14",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, size, asChild = false, ...props }, ref) => {
  const Comp = asChild ? Slot : "button";
  return <Comp className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
});
Button.displayName = "Button";

export { Button, buttonVariants };
