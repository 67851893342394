import { LoadingSpinner } from "@/components/LoadingSpinner.tsx";
import { OrderDetailsSidebarSummary } from "@/components/orders/OrderDetailsSidebarSummary.tsx";
import PaymentMethodForm from "@/components/payment/PaymentMethodForm.tsx";
import { Button } from "@/components/ui/button";
import { useIsInRole } from "@/hooks";
import { OrderReceiptPage } from "@/pages/app/orders/OrderReceiptPage.tsx";
import { useAppDispatch } from "@/store/Hooks.ts";
import { ApplicationRole, DistributorDto, OrderStatus, useGetOrderDetailsByIdQuery, useSubmitPaymentMutation } from "@/store/IRN.API.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { useEffect, useState } from "react";
// import "react-credit-cards-2/dist/es/styles-compiled.css";
import { useNavigate, useParams } from "react-router-dom";

export function OrderCheckoutPage() {
  const { orderId: orderIdParam } = useParams();
  // decompose data.order into just order. ( { data: { order: { ... } } )
  const { data, isSuccess: getOrderIsSuccess, isLoading: orderIsLoading /*, isLoading, error*/ } = useGetOrderDetailsByIdQuery(parseInt(orderIdParam!));
  const [orderItemsByDistributor, setOrderItemsByDistributor] = useState<{
    [key: string]: {
      id: number;
      quantity: number;
      product: {
        name: string;
        price: number;
        distributor: DistributorDto;
      };
    }[];
  }>({});
  const isDeveloperRole = useIsInRole(ApplicationRole.Developer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [submitPaymentTrigger, submitPaymentResult] = useSubmitPaymentMutation();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<number | null>(null);

  useEffect(() => {
    if (submitPaymentResult.isSuccess) {
      console.log("Payment submitted successfully");
      dispatch(showNotification({ message: "Payment submitted successfully", severity: "success" }));
      navigate("../receipt", { relative: "path" });
    }
  }, [submitPaymentResult.isSuccess]);

  useEffect(() => {
    if (!data?.order || !getOrderIsSuccess) {
      return;
    }

    if (data?.order.status !== OrderStatus.NotPlaced) {
      navigate("../receipt", { relative: "path" });
    }

    const itemsByDistributor: { [key: string]: { id: number; quantity: number; product: { name: string; price: number; distributor: DistributorDto } }[] } = {};
    data?.order.lineItems?.forEach((item) => {
      if (!itemsByDistributor[item.product!.distributor!.name!]) {
        itemsByDistributor[item.product!.distributor!.name!] = [];
      }
      itemsByDistributor[item.product!.distributor!.name!].push({
        quantity: item.quantity!,
        id: item.id!,

        product: {
          name: item.product!.name!,
          price: item.product!.price!,
          distributor: item.product!.distributor!,
        },
      });
    });

    // if (isDeveloperRole) {
    //   // Duplicate each order/distributor 3 times so we can see some more data presented for layout testing
    //   Object.keys(itemsByDistributor).forEach((distributor) => {
    //     itemsByDistributor[distributor + " - #2"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #3"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #4"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #5"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #6"] = [...itemsByDistributor[distributor]];
    //     itemsByDistributor[distributor + " - #7"] = [...itemsByDistributor[distributor]];
    //   });
    // }

    setOrderItemsByDistributor(itemsByDistributor);
  }, [getOrderIsSuccess, isDeveloperRole, data?.order]);

  function submitPayment() {
    submitPaymentTrigger({
      businessId: data!.order!.business!.id,
      submitPaymentRequest: {
        orderId: data!.order!.id,
        paymentMethodId: selectedPaymentMethod ?? 0,
        useAvailableCredit: false,
      },
    });
  }

  if (orderIsLoading || !data?.order) {
    return <LoadingSpinner size="4xl" />;
  }

  if (submitPaymentResult.isSuccess) {
    return <OrderReceiptPage />;
  }

  return (
    <div className="grid gap-5 sm:grid-cols-2 sm:gap-10">
      <PaymentMethodForm order={data.order!} selectedPaymentMethod={selectedPaymentMethod} setSelectedPaymentMethod={setSelectedPaymentMethod} />

      <div>
        {data.order && (
          <>
            <OrderDetailsSidebarSummary
              order={data.order}
              orderId={data.order.orderId!}
              createdAt={data.order.createdAt!}
              status={data.order.status!}
              business={data.order.business!}
              orderItemsByDistributor={orderItemsByDistributor}
            >
              {submitPaymentResult.isError && (
                <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-5" role="alert">
                  <strong className="font-bold">Error: </strong>
                  <span className="block sm:inline">
                    {(submitPaymentResult.error as any).data?.errors?.Payment?.[0] ?? "The payment could not be processed."}
                  </span>
                  <br />
                  <span className="block sm:inline text-xs">{(submitPaymentResult.error as any).data?.errors?.ErrorCode?.[0] ?? "UNKNOWN"}</span>
                </div>
              )}

              <Button className="w-full max-w-2xl mx-auto" onClick={submitPayment} disabled={submitPaymentResult.isLoading}>
                {submitPaymentResult.isLoading ? (
                  <>
                    <LoadingSpinner size="xs" />
                    Submitting payment
                  </>
                ) : (
                  "Submit payment"
                )}
              </Button>
            </OrderDetailsSidebarSummary>
          </>
        )}
      </div>
    </div>
  );
}
