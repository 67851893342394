import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { BusinessForm } from "@/components/forms/BusinessForm.tsx";
import { businessFormValidation, businessFormValidationRequireAllFields } from "@/components/forms/BusinessFormValidation.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form } from "@/components/ui/form.tsx";
import { selectBusinesses } from "@/store/Business.slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { BusinessDto, CreateOrUpdateBusinessRequest, useDeleteBusinessMutation, useUpdateBusinessMutation } from "@/store/IRN.API.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { TrashIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export function UpdateBusinessInfoDialog({ children, businessId, requireFields }: { children: ReactNode; businessId: number; requireFields?: boolean }) {
  const [open, setOpen] = useState(false);
  return ControllableUpdateBusinessInfoDialog({ children, businessId, requireFields, open, setOpen, hasTrigger: true });
}

export function ControllableUpdateBusinessInfoDialog({
  children,
  businessId,
  requireFields,
  open,
  setOpen,
  hasTrigger = false,
}: {
  children?: ReactNode;
  businessId: number;
  requireFields?: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
  hasTrigger?: boolean;
}) {
  const businesses = useAppSelector(selectBusinesses);
  const business: BusinessDto | undefined = businesses?.find((b) => b.id === businessId);

  const [updateTrigger, updateResult] = useUpdateBusinessMutation();
  const [deleteTrigger] = useDeleteBusinessMutation();
  const confirmation = useConfirm();

  const dispatch = useAppDispatch();
  const form = useForm<CreateOrUpdateBusinessRequest>({
    mode: "onBlur",
    resolver: yupResolver(requireFields ? businessFormValidationRequireAllFields : businessFormValidation),

    defaultValues: {
      name: business?.name,
      address: business?.address,
      email: business?.email,
      operatingName: business?.operatingDbaName,
      phoneNumber: business?.phoneNumber,
      taxId: business?.taxId,
      registrationType: business?.registrationType,
    },
  });

  useEffect(() => {
    if (updateResult.isSuccess) {
      setOpen(false);
      dispatch(showNotification({ message: "Business info updated", severity: "success" }));
    }
  }, [dispatch, updateResult.isSuccess]);

  const onSubmit = (data: CreateOrUpdateBusinessRequest) => {
    updateTrigger({
      businessId: businessId,
      createOrUpdateBusinessRequest: data,
    });
  };

  const deleteBusiness = () => {
    confirmation({
      title: "Delete business",
      body: (
        <div className="grid gap-2">
          <p>Are you sure you want to delete this business?</p>
          <p className="text-lg font-semibold">{business?.name}</p>
          <p className="font-bold text-destructive">This action cannot be undone.</p>
        </div>
      ),
      actionVariant: "destructive",
      actionButton: "Delete",
      cancelVariant: "secondary",
      cancelButton: "Cancel",
    }).then((confirmed) => {
      if (confirmed) {
        deleteTrigger(businessId)
          .unwrap()
          .then(() => {
            dispatch(showNotification({ message: "Business deleted", severity: "success" }));
            setOpen(false);
          });
      }
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      {hasTrigger && (
        <DialogTrigger asChild>
          {children}
          {/*<Button variant="link">Update</Button>*/}
        </DialogTrigger>
      )}
      <DialogContent className="max-w-xl">
        <DialogHeader>
          <DialogTitle>Edit business info</DialogTitle>
          <DialogDescription>Make changes to your business info here. Click save when you're done.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <BusinessForm control={form.control} namePrefix="" dialog requireFields={requireFields} />

            <div className="flex flex-row justify-between gap-4 space-x-2">
              {/* Delete button */}
              {!requireFields && (
                <div>
                  <Button variant="secondary" type="button" onClick={deleteBusiness}>
                    <TrashIcon className="mr-2 h-5 w-5" />
                    Delete
                  </Button>
                </div>
              )}
              <div>
                <Button type="button" variant="link" onClick={() => setOpen(false)}>
                  Cancel
                </Button>
                <Button type="submit">Save</Button>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
