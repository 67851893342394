import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

export function toCurrency(amount?: number, zeroIsNa = true): string {
  if (amount === undefined) {
    return "$0.00";
  }

  if (amount === 0 && zeroIsNa) {
    return "n/a";
  }

  return amount.toLocaleString("en-US", { style: "currency", currency: "USD" });
}

export interface SerializableJsFile {
  base64StringFile: string;
  name: string;
  type: string;
  size: number;
}

export function getSerializableJsFile(file: File): Promise<SerializableJsFile> {
  return new Promise<SerializableJsFile>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      // Log.debug("getSerializableJsFile: reader.onload");
      // Log.debug(reader.result?.toString());

      //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
      // const bytes = Array.from(new Uint8Array(reader.result! as ArrayBuffer));
      // //if you want the base64encoded file you would use the below line:
      // const base64StringFile = btoa(bytes.map((item) => String.fromCharCode(item)).join(""));

      const fileDetails = {
        base64StringFile: reader.result?.toString() || "",
        name: file.name,
        type: file.type,
        size: file.size,
      };
      // Log.debug("getSerializableJsFile: fileDetails", fileDetails);
      resolve(fileDetails);
    };
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

export interface ProblemDetails {
  type?: string;
  title?: string;
  status?: number;
  detail?: string;
  instance?: string;
  errors?: Record<string, string[]>;
  extensions?: Record<string, any>;
}

export function isProblemDetails(data: any): data is ProblemDetails {
  if (!data || !data.type) return false;
  return data.type.startsWith("https://tools.ietf.org/html/rfc9110");
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"] : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}

export function isMutationErrorResult(result: any): result is { error: SerializedError | FetchBaseQueryError } {
  return "error" in result;
}

export function isMutationDataResult<T>(result: any): result is { data: T } {
  return "data" in result;
}

export function isMutationSerializedErrorResult(result: any): result is { error: SerializedError } {
  return "error" in result && !("status" in result.error);
}

export function isMutationFetchBaseQueryErrorResult(result: any): result is { error: FetchBaseQueryError } {
  return "error" in result && "status" in result.error;
}

/**
 * Linearly interpolate between two colors.
 * @param from A hex color string to interpolate from.
 * @param to A hex color string to interpolate to.
 * @param percent A number between 0 and 1. 0 will return `from`, 1 will return `to`. Values in between will be a linear interpolation between the two colors.
 */
export function colorLerp(from: string, to: string, percent: number) {
  // Convert the hex colors to RGB values
  const r1 = parseInt(from.substring(1, 3), 16);
  const g1 = parseInt(from.substring(3, 5), 16);
  const b1 = parseInt(from.substring(5, 7), 16);

  const r2 = parseInt(to.substring(1, 3), 16);
  const g2 = parseInt(to.substring(3, 5), 16);
  const b2 = parseInt(to.substring(5, 7), 16);

  // Interpolate the RGB values
  const r = Math.round(r1 + (r2 - r1) * percent);
  const g = Math.round(g1 + (g2 - g1) * percent);
  const b = Math.round(b1 + (b2 - b1) * percent);

  // Convert the interpolated RGB values back to a hex color
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function interpolateColors(colors: string[], value: number): string {
  // Ensure the value is between 0.0 and 1.0
  const safeValue = Math.min(Math.max(value, 0.0), 1.0);

  // Calculate which colors to interpolate between and the interpolation fraction
  const totalSections = colors.length - 1;
  const scaledValue = safeValue * totalSections;
  const startIndex = Math.floor(scaledValue);
  const endIndex = Math.min(startIndex + 1, totalSections);
  const fraction = scaledValue - startIndex;

  // Helper to convert hex to RGB
  const hexToRgb = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  // Helper to convert RGB to hex
  const rgbToHex = (r: number, g: number, b: number) => {
    return (
      "#" +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("")
    );
  };

  // Parse start and end colors
  const startColor = hexToRgb(colors[startIndex]);
  const endColor = hexToRgb(colors[endIndex]);

  // Interpolate between the two colors
  const interpolatedColor = startColor.map((component, index) => {
    return Math.round(component + (endColor[index] - component) * fraction);
  });

  // Return the interpolated color in hex format
  return rgbToHex(interpolatedColor[0], interpolatedColor[1], interpolatedColor[2]);
}

export function isToday(date: Date) {
  const today = new Date();
  return date.getDate() === today.getDate() && date.getMonth() === today.getMonth() && date.getFullYear() === today.getFullYear();
}
