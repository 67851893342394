import { Button } from "@/components/ui/button.tsx";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { OrderGuideUploadDto, OrderGuideUploadEntryDto, UploadProcessingStatus, useConfirmOrderGuideUploadEntryMutation } from "@/store/IRN.API.ts";
import * as ProductMatching from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { createCustomEntryProduct } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { PlusIcon } from "@heroicons/react/24/solid";
import { useEffect } from "react";

export function ReviewAndMatchItemsPaginationButtons(props: {
  uploadEntriesLength: number;
  showMatchingProductDisplay: boolean;
  entry: undefined | OrderGuideUploadEntryDto;
  upload: undefined | OrderGuideUploadDto;
}) {
  const dispatch = useAppDispatch();
  const [confirmEntryTrigger, confirmEntryResult] = useConfirmOrderGuideUploadEntryMutation();
  const currentEntryIndex = useAppSelector(ProductMatching.selectCurrentEntryIndex) ?? 0;

  useEffect(() => {
    if (confirmEntryResult.isSuccess && confirmEntryResult.status === "fulfilled") {
      dispatch(ProductMatching.setCurrentEntryConfirmed());
      dispatch(ProductMatching.gotoNextEntry(1));
    }
  }, [confirmEntryResult]);

  return (
    <div className="grid gap-2">
      <div className="grid grid-flow-row grid-cols-3 gap-2">
        <Button size="sm" variant="outline" onClick={() => dispatch(ProductMatching.gotoNextEntry(-1))} disabled={currentEntryIndex === 0}>
          Previous
        </Button>
        <Button
          size="sm"
          variant="secondary"
          onClick={() => dispatch(ProductMatching.gotoNextEntry(1))}
          disabled={currentEntryIndex === props.uploadEntriesLength - 1}
        >
          Skip
        </Button>
        <Button
          variant="success"
          size="sm"
          onClick={() => {
            if (props.entry?.status === UploadProcessingStatus.Confirmed) {
              dispatch(ProductMatching.gotoNextEntry(1));
            } else {
              confirmEntryTrigger({
                orderGuideUploadId: props.upload!.id,
                entryId: props.entry!.id,
              });
            }
          }}
          disabled={!props.entry?.productMetadataId || props.entry?.productMetadataId === 1}
        >
          {props.entry?.status === UploadProcessingStatus.Confirmed ? "Next" : "Confirm"}
          {/*<RoleGuard roles={[ApplicationRole.Developer]}> {entry?.productLinkId}</RoleGuard>*/}
        </Button>
      </div>

      {props.upload?.entries.find((e) => e.status !== UploadProcessingStatus.Confirmed) && (
        <Button variant="outline-success" size="sm" onClick={() => dispatch(ProductMatching.goToNextUnconfirmedEntry())}>
          Next unconfirmed
        </Button>
      )}

      {!props.showMatchingProductDisplay && (
        <Button
          variant="success"
          size="sm"
          onClick={() => {
            console.log("creating custom product & confirm");
            dispatch(
              createCustomEntryProduct({
                entryId: props.entry!.id,
                uploadId: props.upload!.id,
                confirm: true,
              }),
            );
          }}
        >
          <PlusIcon className="mr-1 h-5 w-5" />
          Create custom product & Confirm
        </Button>
      )}
    </div>
  );
}
