import { PublicFooter } from "@/components/layout/PublicFooter.tsx";
import { PublicHeader } from "@/components/layout/PublicHeader.tsx";
import { Button } from "@/components/ui/button.tsx";
import { ScaleIcon, UsersIcon } from "@heroicons/react/24/outline";
import { FastForwardIcon } from "lucide-react";
import { Link } from "react-router-dom";

const stats = [
  { label: "Average buying group cost", value: "22%" },
  //  of food purchase price, 40% of rebates
  { label: "Average buying group rebate withheld", value: "45%" },
  { label: "Average # of distributors", value: "2" },
];

const features = [
  {
    name: "Purchase order comparisons",
    description:
      "Compare prices on products across multiple distributors to ensure you&apos;re getting the best deal. Want to continue to use your distributors? No problem. Upload your own information, and we&apos;ll compare them for you.",
    href: "#",
    icon: ScaleIcon,
  },
  {
    name: "Easy order guide creation",
    description:
      "Simply find your products in our catalog, and add them to your order guide. It&apos;s that easy. We offer both PAR and Quantity based order guide functionality.",
    href: "#",
    icon: UsersIcon,
  },
  {
    name: "Simple order placing",
    description:
      "Create an order guide. Click Order. We&apos;ll compare prices, and create the cheapest order for you, spanning multiple distributors. We&apos;ll even place the orders for you. You just have to wait for the delivery.",
    href: "#",
    icon: FastForwardIcon,
  },
];

export function HomeV3() {
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div className="bg-white">
      <PublicHeader wrapperClassName="absolute inset-x-0 top-0 z-50" />
      <div className="relative lg:h-screen">
        <div className="mx-auto max-w-6xl">
          <div className="relative z-10 pt-14 lg:w-full lg:max-w-2xl">
            <svg
              className="absolute inset-y-0 right-8 hidden h-screen w-80 translate-x-1/2 transform fill-white lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className="relative px-6 py-32 sm:py-40 lg:px-8 lg:py-56 lg:pr-0">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <div className="hidden sm:mb-10 sm:flex">
                  <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-500 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    Get started with no up front costs.{" "}
                    <a href="#" className="whitespace-nowrap font-semibold text-brand-500">
                      <span className="absolute inset-0" aria-hidden="true" />
                      Read more <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">A better buying group experience</h1>
                <p className="mt-6 text-lg leading-8 text-gray-600">
                  We provide the best buying group experience for your independent restaurant. Our platform is designed to help you save time and money by
                  providing you with the best deals on the market, and make sure you&apos;re getting your money&apos;s worth.
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Button size="lg" asChild>
                    <Link to={"/login"} className="font-semibold">
                      Get started
                    </Link>
                  </Button>
                  {/*<a*/}
                  {/*  href="#"*/}
                  {/*  className="rounded-md bg-brand-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-brand-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brand-500"*/}
                  {/*>*/}
                  {/*  Get started*/}
                  {/*</a>*/}
                  <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
                    Learn more <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-hidden bg-gray-50 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img className="aspect-[3/2] object-cover lg:aspect-auto lg:h-full lg:w-full" src="cafe2.jpg" alt="" />
        </div>
      </div>

      <div className="py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Save money. Now. For free.</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Use our distributor price comparison tool, free of charge* to see what we can offer you. Don&apos;t want to use our partners? No problem. Keep
              your existing relationships and use our platform only if it makes sense for you.
            </p>
            <p className="text-xs text-gray-500">*Free for the first 20 order creations. $100 per month after that.</p>
          </div>

          <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
            <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
              {features.map((feature) => (
                <div key={feature.name} className="flex flex-col">
                  <dt className="text-base font-semibold leading-7 text-gray-900">
                    <div className="mb-6 flex h-10 w-10 items-center justify-center rounded-lg bg-brand-500">
                      <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                    </div>
                    {feature.name}
                  </dt>
                  <dd className="mt-1 flex flex-auto flex-col text-base leading-7 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                    <p className="mt-6">
                      <a href={feature.href} className="text-sm font-semibold leading-6 text-brand-500">
                        Learn more <span aria-hidden="true">→</span>
                      </a>
                    </p>
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>

      <div className="bg-cover bg-center py-32 lg:bg-burgers">
        <div className="mx-auto max-w-7xl px-6 lg:bg-white lg:px-8 lg:py-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Our mission</h2>
            <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
              <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                <p className="text-xl leading-8 text-gray-600">
                  Provide a transparent, easy to use platform that helps independent restaurants save money on their purchases. We want to help you make
                  informed decisions on your purchases, and help you save money where it makes sense for you. We&apos;re restaurant owners too. We know when
                  things work, and when they don&apos;t.
                </p>
                <p className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                  IRN strives to be transparent in pricing, vendor relationships, and how/where our money is made. We&apos;ve seen what happens when deals are
                  made behind closed doors. We want to change that. We want to provide a platform that caters to the needs of the independent restaurant owner.
                  Not the needs of the boardroom.
                </p>
              </div>
              <div className="lg:flex lg:flex-auto lg:justify-center">
                <dl className="w-64 space-y-8 xl:w-80">
                  {stats.map((stat) => (
                    <div key={stat.label} className="flex flex-col-reverse gap-y-4">
                      <dt className="text-base leading-7 text-gray-600">{stat.label}</dt>
                      <dd className="text-5xl font-semibold tracking-tight text-gray-900">{stat.value}</dd>
                    </div>
                  ))}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              We&apos;re restaurant owners too. We know when things work, and when they don&apos;t.
            </h2>
            <p className="mt-6 text-base leading-7 text-gray-600">
              Most buying groups lock you into their distributors, products, or contracts. We don&apos;t. We want you to use our platform because it makes sense
              for you. No contracts. No commitments. Use us when you want, and how you want.
            </p>
          </div>

          <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-50 p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
              <p className="flex-none text-3xl font-bold tracking-tight text-gray-900">3+</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-gray-900">Preferred distributors</p>
                <p className="mt-2 text-base leading-7 text-gray-600">
                  We work closely with 3+ preferred distributors, to get you the best price. Don&apos;t like ours? Bring your own!
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-gray-900 p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
              <p className="flex-none text-3xl font-bold tracking-tight text-white">100k+</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-white">Products available</p>
                <p className="mt-2 text-base leading-7 text-gray-400">
                  We have over 100,000 products available for you to purchase. Don&apos;t see what you need? Let us know, and we&apos;ll get it for you. Have a
                  vendor you want to use? Upload the information, and we&apos;ll compare it for you.
                </p>
              </div>
            </div>
            <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-brand-500 p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
              <p className="flex-none text-3xl font-bold tracking-tight text-white">3+ generations</p>
              <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
                <p className="text-lg font-semibold tracking-tight text-white">Knowledge of the restaurant industry</p>
                <p className="mt-2 text-base leading-7 text-brand-100">
                  We&apos;ve been in the restaurant industry for over 3 generations. We&apos;ve seen it all.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:justify-between lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ready to dive in?
            <br />
            Join to start saving like never before.
          </h2>
          <div className="mt-10 flex items-center gap-x-6 lg:mt-0 lg:flex-shrink-0">
            <Button size="lg" asChild>
              <Link to={"/login"} className="font-semibold">
                Get started
              </Link>
            </Button>
            <a href="#" className="text-sm font-semibold leading-6 text-gray-900">
              Learn more <span aria-hidden="true">→</span>
            </a>
          </div>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
}
