import { useConfirm } from "@/components/dialogs/confirmation-dialog/ConfirmationDialog.tsx";
import { Button } from "@/components/ui/button.tsx";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu.tsx";
import { OrderGuideDisplayDto, OrderGuideItemDto, useRemoveProductFromOrderGuideMutation } from "@/store/IRN.API.ts";
import { MoreVertical, Trash } from "lucide-react";

export function OrderGuideItemListingActionButton({ item, orderGuide }: { item: OrderGuideItemDto; orderGuide: OrderGuideDisplayDto }) {
  const confirmationDialog = useConfirm();
  const [deleteOrderGuideItemTrigger] = useRemoveProductFromOrderGuideMutation();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="-mx-2 px-2">
          <MoreVertical className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="">
        <DropdownMenuLabel>Actions</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem
          className="cursor-pointer text-red-500 hover:bg-red-200 focus:bg-red-200 focus:text-red-600"
          onClick={async () => {
            const confirmed = await confirmationDialog({
              title: "Remove Item",
              body: (
                <div className="flex flex-col gap-5">
                  <p>Are you sure you want to delete this item?</p>
                  <p className="font-semibold">{item.productMetadata?.name}</p>
                  <p className="font-semibold text-destructive">This action cannot be undone.</p>
                </div>
              ),
              actionButton: "Delete",
              actionVariant: "destructive",
              cancelButton: "Cancel",
              cancelVariant: "link",
            });

            if (confirmed) {
              deleteOrderGuideItemTrigger({
                orderGuideId: orderGuide!.id,
                productId: item.productMetadata!.id!,
              });
            }
          }}
        >
          {/* Delete */}
          <Trash className="mr-2 h-4 w-4" />
          <span>Remove</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
