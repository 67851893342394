import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { useGetOrderGuideSummariesQuery } from "@/store/IRN.API.ts";
import { selectBusinessOrderGuidesForBusiness, setOrderGuideSummaries } from "@/store/OrderGuideWidget.slice.ts";
import { useEffect } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs.tsx";
import { NoOrderGuides } from "./NoOrderGuides.tsx";
import { OrderGuideSelection } from "./OrderGuideSelection.tsx";
import { UploadOrderGuide } from "./UploadOrderGuide.tsx";

export function OrderGuideSelectionWidget({ compact = false }: { compact?: boolean }) {
  const orderGuides = useGetOrderGuideSummariesQuery();
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const dispatch = useAppDispatch();

  const businessOrderGuides = useAppSelector(selectBusinessOrderGuidesForBusiness(activeBusiness!.id!));

  useEffect(() => {
    // console.log(orderGuides);
    if (orderGuides.data && orderGuides.isSuccess) {
      dispatch(setOrderGuideSummaries(orderGuides.data?.summaries ?? []));
    }
  }, [orderGuides, orderGuides.data, orderGuides.isSuccess]);

  if (compact) {
    return (
      <div className="flex flex-col gap-2">
        <OrderGuideSelection refetchSummaries={orderGuides.refetch} compact={compact} />
      </div>
    );
  }

  return (
    <div className="order-guide-selection-widget">
      <h2 className="font-semibold">Order Guides</h2>

      {(businessOrderGuides?.guides?.length ?? 0) === 0 && (
        <>
          <NoOrderGuides refetchSummaries={orderGuides.refetch} />

          <hr className="mt-4" />
          <UploadOrderGuide />
        </>
      )}
      {(businessOrderGuides?.guides?.length ?? 0) > 0 && (
        <>
          <Tabs defaultValue="guides" className="flex flex-col">
            <TabsList className="shrink-0 flex border-b border-mauve6">
              <TabsTrigger value="guides">Order Guides</TabsTrigger>
              <TabsTrigger value="upload">Uploads</TabsTrigger>
            </TabsList>
            <TabsContent value="guides">
              <OrderGuideSelection refetchSummaries={orderGuides.refetch} compact={compact} />
            </TabsContent>
            <TabsContent value="upload">
              <UploadOrderGuide />
            </TabsContent>
          </Tabs>
        </>
      )}
    </div>
  );
}
