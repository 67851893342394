import { BusinessForm } from "@/components/forms/BusinessForm.tsx";
import { businessFormValidation, businessFormValidationRequireAllFields } from "@/components/forms/BusinessFormValidation.tsx";
import { Button } from "@/components/ui/button.tsx";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog.tsx";
import { Form } from "@/components/ui/form.tsx";
import { selectUserInfo } from "@/store/Auth.Slice.ts";
import { useAppDispatch, useAppSelector } from "@/store/Hooks.ts";
import { CreateOrUpdateBusinessRequest, UserInfoDto, useCreateBusinessMutation } from "@/store/IRN.API.ts";
import { showNotification } from "@/store/Toast.slice.ts";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactNode, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

export function CreateBusinessInfoDialog({ children, requireFields }: { children: ReactNode; requireFields?: boolean }) {
  const [open, setOpen] = useState(false);
  const userInfo: UserInfoDto | undefined = useAppSelector(selectUserInfo);
  const [updateTrigger, updateResult] = useCreateBusinessMutation();

  const dispatch = useAppDispatch();
  const form = useForm<CreateOrUpdateBusinessRequest>({
    mode: "onBlur",
    resolver: yupResolver(requireFields ? businessFormValidationRequireAllFields : businessFormValidation),

    defaultValues: {
      email: userInfo?.email,
      phoneNumber: userInfo?.phoneNumber,
    },
  });

  useEffect(() => {
    if (updateResult.isSuccess) {
      setOpen(false);
      dispatch(showNotification({ message: "Business info created", severity: "success" }));
    }
  }, [dispatch, updateResult.isSuccess]);

  const onSubmit = (data: CreateOrUpdateBusinessRequest) => {
    console.log(data);
    updateTrigger(data);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {children}
        {/*<Button variant="link">Update</Button>*/}
      </DialogTrigger>
      <DialogContent className="max-h-screen max-w-xl overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>Register a new business</DialogTitle>
          <DialogDescription>Please provide the details listed below. Note that not all fields are required.</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form className="space-y-6" onSubmit={form.handleSubmit(onSubmit)}>
            <BusinessForm control={form.control} namePrefix="" dialog />

            <DialogFooter>
              <Button type="submit">Save</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
