import { ClipboardCheck, DollarSign, LucideArrowBigUp, MessageCircleQuestionIcon, ReplyIcon, ShoppingCart } from "lucide-react";
import * as React from "react";

import { NavMain } from "@/components/nav-main";
import { NavUser } from "@/components/nav-user";
import { TeamSwitcher } from "@/components/team-switcher";
import { Sidebar, SidebarContent, SidebarFooter, SidebarHeader, SidebarRail } from "@/components/ui/sidebar";
import { selectActiveBusiness } from "@/store/Business.slice.ts";
import { useAppSelector } from "@/store/Hooks.ts";
import { useGetOrderGuideSummariesQuery } from "@/store/IRN.API.ts";
import { HomeIcon } from "@heroicons/react/24/outline";
import { BrowserClient, Feedback, getClient } from "@sentry/react";
import { useEffect, useState } from "react";
import { useMatches } from "react-router-dom";

// This is sample data.
const data = {
  user: {
    name: "shadcn",
    email: "m@example.com",
    avatar: "/avatars/shadcn.jpg",
  },
  navMain: [
    {
      title: "Dashboard",
      url: "/app/dashboard",
      icon: HomeIcon,
      isActive: true,
      items: [
        {
          title: "Overview",
          url: "/app/dashboard",
        },
        // {
        //   title: "Performance",
        //   url: "/app/dashboard/performance",
        // },
        {
          title: "Reports",
          url: "/app/dashboard/reports",
        },
      ],
    },
    {
      title: "Order Guides",
      url: "/app/order-guide",
      icon: ClipboardCheck,

      items: [
        {
          title: "All Order Guides",
          url: "/app/order-guide",
        },
        {
          title: "Uploads",
          url: "/app/order-guide/uploads",
        },
        {
          title: "Create Order Guide",
          url: "/app/order-guide/create",
        },
      ],
    },
    {
      title: "Products",
      url: "/app/products",
      icon: ShoppingCart,
      items: [
        {
          title: "All Products",
          url: "/app/products",
        },
        // {
        //   title: "Create Product",
        //   url: "/app/products/create",
        // },
      ],
    },
    {
      title: "Orders",
      url: "/app/orders",
      icon: DollarSign,
      items: [
        {
          title: "All Orders",
          url: "/app/orders",
        },
        // {
        //   title: "Create Order",
        //   url: "/app/orders/create",
        // },
      ],
    },
    {
      title: "Rebates",
      url: "/app/rebates",
      icon: LucideArrowBigUp,
      items: [
        {
          title: "My Rebates",
          url: "/app/rebates",
        },
      ],
    },
    {
      title: "Support",
      url: "https://help.irnworks.com",
      icon: MessageCircleQuestionIcon,
    },
    {
      title: "Feedback",
      // url: "https://feedback.irnworks.com",
      icon: ReplyIcon,
      url: "#",
    },
  ],
  projects: [
    {
      name: "Products",
      url: "/app/products",
      icon: ShoppingCart,
    },
    {
      name: "Order Guides",
      url: "/ap/order-guide",
      icon: ClipboardCheck,
    },
    {
      name: "Orders",
      url: "/app/orders",
      icon: DollarSign,
    },
    {
      name: "Rebates",
      url: "/app/rebates",
      icon: DollarSign,
    },
    {
      name: "Support",
      url: "https://help.irnworks.com",
      icon: MessageCircleQuestionIcon,
    },
  ],
};

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  // const dispatch = useAppDispatch();
  // const userInfo = useAppSelector(selectUserInfo);
  // const businesses = useAppSelector(selectBusinesses);
  const activeBusiness = useAppSelector(selectActiveBusiness);
  const allOrderGuidesQuery = useGetOrderGuideSummariesQuery();

  const matches = useMatches();
  const [navigation, setNavigation] = useState(data.navMain);

  const client = getClient<BrowserClient>();
  const feedback = client?.getIntegrationByName<Feedback>("Feedback");

  useEffect(() => {
    const newNavigation = data.navMain.map((item) => {
      const newItem = { ...item };
      newItem.isActive = matches.some((match) => item.url && (match.pathname.includes(item.url) || match.pathname === item.url));
      return newItem;
    });

    // Find the feedback item and set the onClick handler to execute the feedback.openDialog() method
    const feedbackItem: any = newNavigation.find((item) => item.title === "Feedback");
    if (feedbackItem) {
      feedbackItem.onClick = () => feedback?.openDialog();
    }

    setNavigation(newNavigation);
  }, [matches]);

  useEffect(() => {
    // Get the order guides part of the nav, and include a link to all the order guides under the current business
    const orderGuides = navigation.find((item) => item.title === "Order Guides");
    if (orderGuides) {
      const newOrderGuides = { ...orderGuides };
      newOrderGuides.items = [
        {
          title: "All Order Guides",
          url: `/app/order-guide`,
        },
      ];

      for (const og of allOrderGuidesQuery.currentData?.summaries ?? []) {
        if (og.businessId !== activeBusiness?.id) {
          continue;
        }
        newOrderGuides.items.push({
          title: og.name!,
          url: `/app/order-guide/${og.id}`,
        });
      }

      newOrderGuides.items.push({
        title: "Create Order Guide",
        url: `/app/order-guide/create`,
      });

      newOrderGuides.items.push({
        title: "Uploads",
        url: `/app/order-guide/uploads`,
      });

      setNavigation((prev) => {
        const newNav = [...prev];
        const index = newNav.findIndex((item) => item.title === "Order Guides");
        newNav[index] = newOrderGuides;
        return newNav;
      });
    }
  }, [activeBusiness, allOrderGuidesQuery.currentData]);

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <TeamSwitcher />
      </SidebarHeader>
      <SidebarContent>
        <NavMain items={navigation} />
        {/*<NavProjects projects={data.projects} />*/}
      </SidebarContent>
      <SidebarFooter>
        <NavUser />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  );
}
