import { Label } from "@/components/ui/label.tsx";
import { ProductMetadataDto } from "@/store/IRN.API.ts";
import { ParsedEntryData } from "@/store/order-guide-upload/product-matching/OrderGuideUploadProductMatching.slice.ts";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { CheckCircledIcon, QuestionMarkCircledIcon } from "@radix-ui/react-icons";
import { Fragment } from "react";

export function ProductMatchedInfoDetails({ product, parsedInfo }: { product: ProductMetadataDto; parsedInfo?: ParsedEntryData }) {
  const matchedFields = [
    ["Name", parsedInfo?.Name, product.name],
    ["Brand", parsedInfo?.Brand, product.brand],
    ["UPC", parsedInfo?.UPC, product.upc],
    ["MPN", parsedInfo?.MPN, product.mpn],
    ["Unit Size", parsedInfo?.UnitSize, product.unitSize],
  ];

  return (
    <div className="mt-4">
      <h4 className="mb-2 text-sm font-semibold">Matched Fields</h4>
      <div className="flex flex-col gap-2">
        {/*
        Show individual "badges". Grey for non matching (with red X), green for matching (with check).
        List each field we matched/didn't match on
        If both are empty, don't show anything for that field

         */}
        <div
          className="grid grid-cols-4 items-center gap-2"
          style={{
            gridTemplateColumns: "auto 1fr auto 1fr",
          }}
        >
          {matchedFields.map(([label, entryValue, productValue], index) => (
            <Fragment key={index}>
              {(entryValue || productValue) && (
                <>
                  {entryValue === productValue ? (
                    <>
                      <Label htmlFor={label! + index} className="inline-flex gap-1">
                        <CheckCircledIcon className="h-4 w-4 text-green-600" />
                        {label}
                      </Label>
                      <div className="flex items-center gap-1">
                        <span className="text-sm text-gray-600">{entryValue}</span>
                      </div>
                    </>
                  ) : !entryValue || !productValue ? (
                    <>
                      <Label htmlFor={label! + index} className="inline-flex gap-1">
                        <XCircleIcon className="h-4 w-4 text-red-600" />
                        {label}
                      </Label>
                      <span className="text-sm text-red-800">{entryValue || "<empty>"}</span>
                      <span className="text-sm text-gray-600">vs</span>
                      <span className="text-sm text-red-800">{productValue || "<empty>"}</span>
                    </>
                  ) : (
                    <>
                      <Label htmlFor={label! + index} className="inline-flex gap-1">
                        <QuestionMarkCircledIcon className="h-4 w-4 text-purple-500" />
                        {label}
                      </Label>
                      <span className="text-sm text-red-800">{entryValue || "<empty>"}</span>
                      <span className="text-sm text-gray-600">vs</span>
                      <span className="text-sm text-red-800">{productValue || "<empty>"}</span>
                    </>
                  )}
                </>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}
